import { Col, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { PageSkeleton } from '../../../components/PageSkeleton/index';
import { getDepartmentList } from '../../../store/department/actions';
import { leaveGetList } from '../../../store/leave/actions';
import { GlobalState } from '../../../store/root-reducer';
import { IPageProps } from '../../../utils/types/types';
import { AppCalendar } from './components/AppCalendar';
import { CalendarFilter } from './components/CalendarFilter/index';

interface ICalendarPageProps extends IPageProps {

};

const CalendarInner: React.FC<ICalendarPageProps & ConnectedProps<typeof connector>> = ({
  route,
  departmentsData,
  leavesData,
  getDepartmentList: getDepartmentListAtcion,
  leaveGetList: leaveGetListAction,
}) => {

  const { data: leaves, isLoading: isLoadingLeaves } = leavesData;
  const { data: listDepartments, isLoading } = departmentsData;

  useEffect(() => {
    getDepartmentListAtcion();
  }, [getDepartmentListAtcion]);

  useEffect(() => {
    leaveGetListAction();
  }, [leaveGetListAction]);

  const handleDepartmentChange = (idDepartment: string) => leaveGetListAction({
    filterBy: 'department',
    filterValue: idDepartment,
  });

  return (
    <PageSkeleton pageTitle={route.title}>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <Spin spinning={isLoading}>
            <CalendarFilter
              departments={listDepartments}
              onDepartmentChange={handleDepartmentChange}
            />
          </Spin>
        </Col>
        <Col span={24}>
          <Spin spinning={isLoadingLeaves}>
            <AppCalendar leaves={leaves} />
          </Spin>
        </Col>
      </Row>
    </PageSkeleton>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  departmentsData: state.department.departmentList,
  leavesData: state.leaves.list,
});

const mapDispatchToProps = {
  getDepartmentList,
  leaveGetList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const CalendarPage = connector(CalendarInner);
