import { List } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { IUser } from '../../../../../utils/types/user';
import './list-users-link.less';

interface IListUsersLinkProps {
  users: IUser[];
  idSelectedUser: string;
};

export const ListUsersLink: React.FC<IListUsersLinkProps> = ({ users, idSelectedUser }) => {
  return (
    <>
      <h3>Liste des utilisateurs</h3>
      <List
        className='list-users'
        dataSource={users}
        renderItem={item => (
          <List.Item className={idSelectedUser === item.id ? 'selected' : ''}>
            <Link to={`/users/${item.id}`}>
              {item.firstName} {item.lastName}
            </Link>
          </List.Item>
        )}
      />
    </>
  );
};
