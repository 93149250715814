import { UserOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useWindowResize } from '../../hooks';
import { IUser } from '../../utils/types/user';
import './user-preview.less';


interface IUserPreviewProps {
	user: IUser | null;
	upTitle?: React.ReactNode;
	bordered?: boolean;
	isPreview?: boolean;
}

export const UserPreview: React.FC<IUserPreviewProps> = ({ user, bordered, isPreview, upTitle }) => {

	const isMobile = useWindowResize();

	return (
		<Card
			className={`details-preview ${bordered ? 'no-border' : ''}`}
			title={(
				<>
					{ upTitle}
					<Avatar
						size={{ xs: 60 }}
						src={user?.avatar && `${process.env.REACT_APP_BASE_URL}${user?.avatar.url}`}
						icon={!user?.avatar && <UserOutlined />}
					/>
				</>
			)}
			bordered={false}>
			<Row justify="center" gutter={[16, 16]}>
				<Col span={24}>
					{isMobile ? <h3>Nom et prénom</h3> : <h1>Nom et prénom</h1>}
					<div className="breakline">{user ? `${user.firstName} ${user.lastName}` : '---'}</div>
				</Col>
			</Row>
			<Row justify="center" gutter={[16, 16]}>
				<Col span={24}>
					{isMobile ? <h3>Département</h3> : <h1>Département</h1>}
					<div className="breakline">{user ? user.department?.title : '---'}</div>
				</Col>
			</Row>
			<Row justify="center" gutter={[16, 16]}>
				<Col span={24}>
					{isMobile ? <h3>Occupation</h3> : <h1>Occupation</h1>}
					<div className="breakline">{user ? 'occupation' : '---'}</div>
				</Col>
			</Row>
			<Row justify="center" gutter={[16, 16]}>
				<Col span={24}>
					{isMobile ? <h3>E-mail</h3> : <h1>E-mail</h1>}
					<div className="breakline">{user ? user.email : '---'}</div>
				</Col>
			</Row>
			<Row justify="center" gutter={[16, 16]}>
				<Col span={24}>
					{isMobile ? <h3>Téléphone</h3> : <h1>Téléphone</h1>}
					<div className="breakline">{user ? user.phone : '---'}</div>
				</Col>
			</Row>
			{
				!isMobile && !isPreview && (
					<>
						<Row justify="center" gutter={[16, 16]}>
							<Col span={24}>
								{isMobile ? <h3>CIN</h3> : <h1>CIN</h1>}
								<div className="breakline">{user ? user.cin : '---'}</div>
							</Col>
						</Row>
						<Row justify="center" gutter={[16, 16]}>
							<Col span={24}>
								{isMobile ? <h3>Date de naissance</h3> : <h1>Date de naissance</h1>}
								<div className="breakline">{user ? moment(user.birthday).format('DD/MM/YYYY') : '---'}</div>
							</Col>
						</Row>
						<Row justify="center" gutter={[16, 16]}>
							<Col span={24}>
								{isMobile ? <h3>Date d'embauche</h3> : <h1>Date d'embauche</h1>}
								<div className="breakline">{user ? moment(user.hiringDay).format('DD/MM/YYYY') : '---'}</div>
							</Col>
						</Row>
						<Row justify="center" gutter={[16, 16]}>
							<Col span={24}>
								{isMobile ? <h3>Date de sortie</h3> : <h1>Date de sortie</h1>}
								<div className="breakline">{user ? moment(user.exitDay).format('DD/MM/YYYY') : '---'}</div>
							</Col>
						</Row>
					</>
				)
			}
		</Card>
	)
}
