import { Action } from "redux";
import { ICreateDepartment, IDepartment } from "../../utils/types/department";
import { IErrors, IStateInitializer } from "../../utils/types/types";
import { IUser } from "../../utils/types/user";

/* ================= types ================= */
export interface IDepartmentList extends IStateInitializer {
  data: IDepartment[];
};

export interface IDepartmentTeam extends IStateInitializer {
  data: IUser[];
};

export interface IAddDepartment extends IStateInitializer {
  isCreated: boolean;
};

export interface IUpdateDepartment extends IStateInitializer {
  data: IDepartment | null;
  isUpdated: boolean;
};

export interface IOneDepartment extends IStateInitializer {
  data: IDepartment | null;
};

export interface IDepartmentState {
  AddDepartment: IAddDepartment;
  department: IOneDepartment;
  departmentList: IDepartmentList;
  departmentTeam: IDepartmentTeam;
  UpdateDepartment: IUpdateDepartment;
};
/* ================= types ================= */

/**
 * Add New Department
 */
export const DEPARTMENT_ADD_NEW = 'DEPARTMENT_ADD_NEW';
export type DEPARTMENT_ADD_NEW = typeof DEPARTMENT_ADD_NEW;

export const DEPARTMENT_ADD_NEW_SUCCESS = 'DEPARTMENT_ADD_NEW_SUCCESS';
export type DEPARTMENT_ADD_NEW_SUCCESS = typeof DEPARTMENT_ADD_NEW_SUCCESS;

export const DEPARTMENT_ADD_NEW_ERROR = 'DEPARTMENT_ADD_NEW_ERROR';
export type DEPARTMENT_ADD_NEW_ERROR = typeof DEPARTMENT_ADD_NEW_ERROR;

export interface IDepartmentAddAction extends Action<DEPARTMENT_ADD_NEW> {
  payload: ICreateDepartment;
};

export interface IDepartmentAddSuccessAction extends Action<DEPARTMENT_ADD_NEW_SUCCESS> { };

export interface IDepartmentAddErrorAction extends Action<DEPARTMENT_ADD_NEW_ERROR> {
  payload: IErrors;
};

/**
 * List Departments
 */
export const DEPARTMENT_GET_LIST = 'DEPARTMENT_GET_LIST';
export type DEPARTMENT_GET_LIST = typeof DEPARTMENT_GET_LIST;

export const DEPARTMENT_GET_LIST_SUCCESS = 'DEPARTMENT_GET_LIST_SUCCESS';
export type DEPARTMENT_GET_LIST_SUCCESS = typeof DEPARTMENT_GET_LIST_SUCCESS;

export const DEPARTMENT_GET_LIST_ERROR = 'DEPARTMENT_GET_LIST_ERROR';
export type DEPARTMENT_GET_LIST_ERROR = typeof DEPARTMENT_GET_LIST_ERROR;

export interface IGetDepartmentListAction extends Action<DEPARTMENT_GET_LIST> { };

export interface IGetDepartmentListSuccessAction extends Action<DEPARTMENT_GET_LIST_SUCCESS> {
  payload: IDepartment[];
};

export interface IGetDepartmentListErrorAction extends Action<DEPARTMENT_GET_LIST_ERROR> {
  payload: IErrors;
};

/**
 * List Users Department
 */
export const DEPARTMENT_TEAM_GET_LIST = 'DEPARTMENT_TEAM_GET_LIST';
export type DEPARTMENT_TEAM_GET_LIST = typeof DEPARTMENT_TEAM_GET_LIST;

export const DEPARTMENT_TEAM_GET_LIST_SUCCESS = 'DEPARTMENT_TEAM_GET_LIST_SUCCESS';
export type DEPARTMENT_TEAM_GET_LIST_SUCCESS = typeof DEPARTMENT_TEAM_GET_LIST_SUCCESS;

export const DEPARTMENT_TEAM_GET_LIST_ERROR = 'DEPARTMENT_TEAM_GET_LIST_ERROR';
export type DEPARTMENT_TEAM_GET_LIST_ERROR = typeof DEPARTMENT_TEAM_GET_LIST_ERROR;

export interface IGetDepartmentTeamListAction extends Action<DEPARTMENT_TEAM_GET_LIST> {
  payload: string;
};

export interface IGetDepartmentTeamListSuccessAction extends Action<DEPARTMENT_TEAM_GET_LIST_SUCCESS> {
  payload: IUser[];
};

export interface IGetDepartmentTeamListErrorAction extends Action<DEPARTMENT_TEAM_GET_LIST_ERROR> {
  payload: IErrors;
};

/**
 * Update Department
 */
export const DEPARTMENT_UPDATE = 'DEPARTMENT_UPDATE';
export type DEPARTMENT_UPDATE = typeof DEPARTMENT_UPDATE;

export const DEPARTMENT_UPDATE_SUCCESS = 'DEPARTMENT_UPDATE_SUCCESS';
export type DEPARTMENT_UPDATE_SUCCESS = typeof DEPARTMENT_UPDATE_SUCCESS;

export const DEPARTMENT_UPDATE_ERROR = 'DEPARTMENT_UPDATE_ERROR';
export type DEPARTMENT_UPDATE_ERROR = typeof DEPARTMENT_UPDATE_ERROR;

export interface IUpdateDepartmentAction extends Action<DEPARTMENT_UPDATE> {
  payload: ICreateDepartment;
};

export interface IUpdateDepartmentSuccessAction extends Action<DEPARTMENT_UPDATE_SUCCESS> { };

export interface IUpdateDepartmentErrorAction extends Action<DEPARTMENT_UPDATE_ERROR> {
  payload: IErrors;
};

/**
 * Get Department by ID
 */
export const DEPARTMENT_GET_BY_ID = 'DEPARTMENT_GET_BY_ID';
export type DEPARTMENT_GET_BY_ID = typeof DEPARTMENT_GET_BY_ID;

export const DEPARTMENT_GET_BY_ID_SUCCESS = 'DEPARTMENT_GET_BY_ID_SUCCESS';
export type DEPARTMENT_GET_BY_ID_SUCCESS = typeof DEPARTMENT_GET_BY_ID_SUCCESS;

export const DEPARTMENT_GET_BY_ID_ERROR = 'DEPARTMENT_GET_BY_ID_ERROR';
export type DEPARTMENT_GET_BY_ID_ERROR = typeof DEPARTMENT_GET_BY_ID_ERROR;

export const DEPARTMENT_GET_BY_ID_CLEAR = 'DEPARTMENT_GET_BY_ID_CLEAR';
export type DEPARTMENT_GET_BY_ID_CLEAR = typeof DEPARTMENT_GET_BY_ID_CLEAR;

export interface IDepartmentGetByIdAtion extends Action<DEPARTMENT_GET_BY_ID> {
  payload: string;
};

export interface IDepartmentGetByIdSuccessAtion extends Action<DEPARTMENT_GET_BY_ID_SUCCESS> {
  payload: IDepartment;
};

export interface IDepartmentGetByIdErrorAtion extends Action<DEPARTMENT_GET_BY_ID_ERROR> {
  payload: IErrors;
};

export interface IDepartmentGetByIdClearAtion extends Action<DEPARTMENT_GET_BY_ID_CLEAR> { };

export type DepartmentActionTypes =
  IDepartmentAddAction
  | IDepartmentAddSuccessAction
  | IDepartmentAddErrorAction
  | IGetDepartmentListAction
  | IGetDepartmentListSuccessAction
  | IGetDepartmentListErrorAction
  | IGetDepartmentTeamListAction
  | IGetDepartmentTeamListSuccessAction
  | IGetDepartmentTeamListErrorAction
  | IUpdateDepartmentAction
  | IUpdateDepartmentSuccessAction
  | IUpdateDepartmentErrorAction
  | IDepartmentGetByIdAtion
  | IDepartmentGetByIdSuccessAtion
  | IDepartmentGetByIdErrorAtion
  | IDepartmentGetByIdClearAtion;
