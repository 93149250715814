import { IErrors } from '../../utils/types/types';
import { IRole } from "../../utils/types/user";
import { IGetRolesAction, IGetRolesErrorAction, IGetRolesSuccessAction, ROLES_GET_LIST, ROLES_GET_LIST_ERROR, ROLES_GET_LIST_SUCCESS } from "./types";

export const getRoles = (): IGetRolesAction => ({
  type: ROLES_GET_LIST,
});

export const getRolesSuccess = (roles: IRole[]): IGetRolesSuccessAction => ({
  payload: roles,
  type: ROLES_GET_LIST_SUCCESS,
});

export const getRolesError = (errors: IErrors): IGetRolesErrorAction => ({
  payload: errors,
  type: ROLES_GET_LIST_ERROR,
});
