import { Layout } from "antd";
import React from "react";

const { Content } = Layout;

interface IAppContentProps {}

export const AppContent: React.FC<IAppContentProps> = ({ children }) => {
	return <Content>{children}</Content>;
};
