import { Drawer, Layout } from "antd";
import React from "react";
import { DesktopMode, MobileMode } from "../../../../components/DeviceDetector";
import { IMe } from "../../../../utils/types/user";
import { Navbar } from "../Navbar";

const { Sider } = Layout;

interface IAppSiderProps {
	me: IMe;
	menuToggled: boolean;
	selectedKey: string;
	onCloseDrawer: () => void;
}

export const AppSider: React.FC<IAppSiderProps> = ({
	me,
	menuToggled,
	selectedKey,
	onCloseDrawer,
}) => {

	return (
		<>
			<MobileMode>
				<Drawer
					placement='left'
					onClose={onCloseDrawer}
					closable={false}
					visible={menuToggled}
					className='drawer-sidebar'
				>
					<Navbar me={me} selectedKey={selectedKey} />
				</Drawer>
			</MobileMode>
			<DesktopMode>
				<Sider className='full-height' width={250}>
					<Navbar me={me} selectedKey={selectedKey} />
				</Sider>
			</DesktopMode>
		</>
	);
};
