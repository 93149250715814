import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Alert, Button, Col, DatePicker, Divider, Form, Input, Popconfirm, Radio, Row, Space } from 'antd';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useState } from 'react';
import { ILeaveById } from '../../../../../store/leave/types';
import { ICreateLeave } from '../../../../../utils/types/leave';
import { IStateInitializer } from '../../../../../utils/types/types';

moment.locale('fr');

const { Item } = Form;

interface IUpdateLeaveFormProps {
  leave: ILeaveById;
  leaveName: string;
  canceLeave: IStateInitializer;
  onUpdateLeave: (leave: ICreateLeave) => void;
  onCancelLeave: () => void;
}

export const UpdateLeaveForm: React.FC<IUpdateLeaveFormProps> = ({
  leave,
  leaveName,
  canceLeave,
  onUpdateLeave,
  onCancelLeave,
}) => {

  const [form] = Form.useForm();

  const { data: thisLeave, isLoading, errors } = leave;
  const { isLoading: isCancelLoading } = canceLeave;

  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);

  const handleStartDateChange = (date: moment.Moment | null) => setStartDate(date);
  const handleEndDateChange = (date: moment.Moment | null) => setEndDate(date);

  /**
   * 
   * Popconfirm handlers
   */

  const handleSubmitForm = (values: any) => onUpdateLeave({
    ...values,
    startingDate: (values.startingDate as moment.Moment).format('YYYY-MM-DD'),
    endingDate: (values.endingDate as moment.Moment).format('YYYY-MM-DD'),
    resumptionDate: (values.resumptionDate as moment.Moment).format('YYYY-MM-DD'),
  });

  if (!thisLeave) return null;

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        ...thisLeave,
        startingDate: moment(thisLeave.startingDate),
        endingDate: moment(thisLeave.endingDate),
        resumptionDate: moment(thisLeave.resumptionDate),
      }}
      onFinish={handleSubmitForm}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Divider orientation="left">Sollicite un {leaveName}</Divider>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={12} xs={24}>
          <Row gutter={16} align="bottom">
            <Col lg={12} xs={24}>
              <Item
                name="startingDate"
                label="Date de début"
                rules={[
                  { required: true, message: 'Veuillez saisir la date de début du congé !' }
                ]}
              >
                <DatePicker placeholder="Date de début de congé" onChange={handleStartDateChange} />
              </Item>
            </Col>
            <Col lg={12} xs={24}>
              <Item name="startingDateExact">
                <Radio.Group>
                  <Radio value="M">Matin</Radio>
                  <Radio value="A">Après midi</Radio>
                </Radio.Group>
              </Item>
            </Col>
          </Row>
        </Col>
        <Col lg={12} xs={24}>
          <Row gutter={16} align="bottom">
            <Col lg={12} xs={24}>
              <Item
                name="endingDate"
                label="Date de fin"
                rules={[
                  { required: true, message: 'Veuillez saisir la date de fin du congé !' }
                ]}
              >
                <DatePicker placeholder="Date de fin de congé" onChange={handleEndDateChange} />
              </Item>
            </Col>
            <Col lg={12} xs={24}>
              <Item name="endingDateExact">
                <Radio.Group>
                  <Radio value="M">Matin</Radio>
                  <Radio value="A">Après midi inclus</Radio>
                </Radio.Group>
              </Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} align="bottom">
        <Col lg={6} xs={24}>
          <Item
            name="resumptionDate"
            label="Date de reprise"
            rules={[
              { required: true, message: 'Veuillez saisir la date de reprise du travail !' }
            ]}
          >
            <DatePicker placeholder="Date de reprise du travail" />
          </Item>
        </Col>
        <Col lg={6} xs={24}>
          <Item name="resumptionDateExact">
            <Radio.Group>
              <Radio value="M">Matin</Radio>
              <Radio value="A">Après midi inclus</Radio>
            </Radio.Group>
          </Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Divider orientation="left">Si le demande hors délai</Divider>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={12} xs={24}>
          <Item
            name="reason"
            label="Justification du retard"
            rules={[
              { required: true, message: 'Veuillez saisir une justification du retard !' }
            ]}
          >
            <Input.TextArea placeholder="Justification..." autoSize={{ minRows: 2, maxRows: 6 }} />
          </Item>
        </Col>
      </Row>

      {
        leave.data?.confirmed ? (
          <Row gutter={[16, 16]}>
            <Col>
              <Space direction="horizontal">
                <Button loading={isLoading} icon={<EditOutlined />} htmlType="submit" type="primary">Modifier la demande</Button>
                <Popconfirm
                  title="Êtes-vous sûr d'annuler cette demande ?"
                  onConfirm={onCancelLeave}
                  okText="Oui, annuler"
                  cancelText="Non"
                >
                  <Button loading={isCancelLoading} icon={<CloseCircleOutlined />} danger type="link">Annuler la demande</Button>
                </Popconfirm>
              </Space>
            </Col>
          </Row>
        ) : (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Alert message="Cette demande a été annulée !" showIcon banner />
              </Col>
            </Row>
          )
      }

    </Form >
  );
};
