import { Card, Typography } from 'antd';
import React from 'react';
import TextLoop from "react-text-loop";

const { Text, Title } = Typography;

interface IListLeavesBalanceProps {

};

export const ListLeavesBalance: React.FC<IListLeavesBalanceProps> = () => {
  return (
    <Card
      bordered={false}
    >
      <Title level={5}>Vos soldes de congés</Title>
      <TextLoop
        springConfig={{ stiffness: 180, damping: 8 }}
      >
        <Text>Congé annuel: <Text type="success" strong>5</Text></Text>
        <Text>Congé maladie: <Text type="success" strong>10</Text></Text>
        <Text>Congé sans solde: <Text type="success" strong>5</Text></Text>
        <Text>Congé de maternité: <Text type="success" strong>10</Text></Text>
        <Text>Congés spéciaux: <Text type="success" strong>2</Text></Text>
      </TextLoop>
      {' '}jour(s)
    </Card>
  );
};
