import { Alert, Button, Col, DatePicker, Divider, Form, Input, Radio, Row, Space } from 'antd';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useEffect, useState } from 'react';
import { IAddLeave } from '../../../../../store/leave/types';
import { ICreateLeave } from '../../../../../utils/types/leave';

moment.locale('fr');

const { Item } = Form;

interface ILeaveFormProps {
  addLeave: IAddLeave;
  onSubmitLeave: (leave: ICreateLeave) => void;
}

export const LeaveForm: React.FC<ILeaveFormProps> = ({
  addLeave,
  onSubmitLeave,
}) => {

  const [form] = Form.useForm();

  const { isLoading, isCreated, errors } = addLeave;

  const [startingDate, setStartingDate] = useState<null | moment.Moment>(null);

  const [endingDate, setEndingDate] = useState<null | moment.Moment>(null);

  useEffect(() => {
    if (isCreated) form.resetFields();
  }, [isCreated]);

  const handleStartingDateChange = (date: moment.Moment | null) => setStartingDate(date);

  const handleEndingDateChange = (date: moment.Moment | null) => setEndingDate(date);

  // Disable previous dates
  const disablePreviousDays = (current: moment.Moment | null) => {
    // Can not select days before current
    if (!startingDate || !current) return false;
    return startingDate > current;
  };

  // Disable previous dates
  const disableDaysBeforeEndingDate = (current: moment.Moment | null) => {
    // Can not select days before current
    if (!endingDate || !current) return false;
    return endingDate > current;
  };

  const handleFormSubmit = (values: any) => {
    if (values) onSubmitLeave({
      ...values,
      startingDate: (values.startingDate as moment.Moment).format('YYYY-MM-DD'),
      startingDateExact: values.startingDateExact,
      endingDate: (values.endingDate as moment.Moment).format('YYYY-MM-DD'),
      endingDateExact: values.endingDateExact,
      resumptionDate: (values.resumptionDate as moment.Moment).format('YYYY-MM-DD'),
      resumptionDateExact: values.resumptionDateExact,
    })
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFormSubmit}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Divider orientation="left">Attention</Divider>
          <Space direction="vertical">
            <Alert
              type="info"
              message={
                <>
                  <div>Sauf en cas de motif exceptionnelle (cas de force majeure, maladie), les demandes doivent être faites avant la date de début des congés dans les délais minimaux suiant:</div>
                  <ul>
                    <li>1 semaine pour une demande de 2 jours maximaux de congés.</li>
                    <li>2 semaines pour une demande de 2 à 5 jours de congés.</li>
                    <li>1 mois pour une demande de plus de 5 jours de congés.</li>
                    <li>31 Mais pour les demandes de congés d'été.</li>
                  </ul>
                </>
              }
              banner
            />
          </Space>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Divider orientation="left">Sollicite un congé</Divider>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={12} xs={24}>
          <Row gutter={16} align="bottom">
            <Col lg={12} xs={24}>
              <Item
                name="startingDate"
                label="Date de début"
                rules={[
                  { required: true, message: 'Veuillez saisir la date de début du congé !' }
                ]}
              >
                <DatePicker placeholder="Date de début de congé" onChange={handleStartingDateChange} />
              </Item>
            </Col>
            <Col lg={12} xs={24}>
              <Item
                name="startingDateExact"
                initialValue="M"
              >
                <Radio.Group>
                  <Radio value="M">Matin</Radio>
                  <Radio value="A">Après midi</Radio>
                </Radio.Group>
              </Item>
            </Col>
          </Row>
        </Col>
        <Col lg={12} xs={24}>
          <Row gutter={16} align="bottom">
            <Col lg={12} xs={24}>
              <Item
                name="endingDate"
                label="Date de fin"
                rules={[
                  { required: true, message: 'Veuillez saisir la date de fin du congé !' }
                ]}
              >
                <DatePicker
                  placeholder="Date de fin de congé"
                  onChange={handleEndingDateChange}
                  disabledDate={disablePreviousDays}
                />
              </Item>
            </Col>
            <Col lg={12} xs={24}>
              <Item name="endingDateExact" initialValue="M">
                <Radio.Group>
                  <Radio value="M">Matin</Radio>
                  <Radio value="A">Après midi inclus</Radio>
                </Radio.Group>
              </Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} align="bottom">
        <Col lg={6} xs={24}>
          <Item
            name="resumptionDate"
            label="Date de reprise"
            rules={[
              { required: true, message: 'Veuillez saisir la date de reprise du travail !' }
            ]}
          >
            <DatePicker
              placeholder="Date de reprise du travail"
              disabledDate={disableDaysBeforeEndingDate}
            />
          </Item>
        </Col>
        <Col lg={6} xs={24}>
          <Item name="resumptionDateExact" initialValue="M">
            <Radio.Group>
              <Radio value="M">Matin</Radio>
              <Radio value="A">Après midi inclus</Radio>
            </Radio.Group>
          </Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Divider orientation="left">Si la demande hors délai</Divider>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={12} xs={24}>
          <Item
            name="reason"
            label="Justification"
          >
            <Input.TextArea
              placeholder="Justification..."
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col>
          <Button loading={isLoading} htmlType="submit" type="primary">Envoyer la demande</Button>
        </Col>
      </Row>
    </Form >
  );
};
