import { Col, Layout, Row } from 'antd';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { EmptyContent } from '../../../components/EmptyContent';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { GlobalState } from '../../../store/root-reducer';
import { getListUsers, getUser, toggleEnableUser } from '../../../store/user/actions';
import { IPageProps } from "../../../utils/types/types";
import { DetailProfile } from './components/DetailProfile';
import { ListUsersLink } from './components/ListUsersLink';
import './profile-page.less';

interface IProfilePageProps extends IPageProps {

}

export const ProfilePageInner: React.FC<IProfilePageProps & ConnectedProps<typeof connector>> = ({
  route,
  match,
  listUsers,
  user,
  me,
  getListUsers: getListUsersAction,
  getUser: getUserAction,
  toggleEnableUser: toggleEnableUserAction,
}) => {

  useEffect(() => {
    getListUsersAction();
  }, [match, getListUsersAction]);

  useEffect(() => {
    getUserAction(match.params.id);
  }, [match, getUserAction]);

  return (
    <PageSkeleton mainLayout={true} pageTitle={route.title} goBackTo='/users'>
      <Layout className='content-profile'>
        <Row gutter={[16, 16]}>
          <Col sm={6} xs={24}>
            <ListUsersLink users={listUsers} idSelectedUser={match.params.id} />
          </Col>
          <Col sm={18}>
            {
              user
                ?
                <DetailProfile user={user} onToggleEnableUser={toggleEnableUserAction} me={me} />
                :
                <EmptyContent description="Aucun utilisateur" />
            }
          </Col>
        </Row>

      </Layout>
    </PageSkeleton>
  )
}

const mapDispatchToProps = {
  getListUsers,
  getUser,
  toggleEnableUser,
};

const mapStateToProps = (state: GlobalState) => ({
  listUsers: state.users.usersList.data,
  user: state.users.user.data,
  me: state.users.me,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ProfilePage = connector(ProfilePageInner);
