import { BankOutlined, CalendarOutlined, ContactsOutlined, DashboardOutlined, FormOutlined, PlusOutlined, TeamOutlined, UnorderedListOutlined } from "@ant-design/icons";
import React from "react";
import { AnonymousLayout } from "../layouts/AnonymousLayout";
import { MainLayout } from "../layouts/MainLayout";
import { Dashboard } from "../pages/Dashboard";
import { AddDepartmentPage } from "../pages/departments/AddDepartmentPage";
import { ListDepartmentsPage } from "../pages/departments/ListDepartementsPage";
import { UpdateDepartmentPage } from "../pages/departments/UpdateDepartmentPage";
import { CalendarPage } from "../pages/leaves/CalendarPage";
import { ListLeavesPage } from "../pages/leaves/ListLeavesPage";
import { RequestLeavePage } from "../pages/leaves/RequestLeavePage";
import { UpdateLeavePage } from "../pages/leaves/UpdateLeavePage";
import { LoginPage } from "../pages/LoginPage";
import { ListUsersPage } from "../pages/users/ListUsersPage";
import { ProfilePage } from "../pages/users/ProfilePage";
import { UpdateProfilePage } from "../pages/users/UpdateProfilePage";
import { ILayout } from "../utils/types/types";
import { generateRoutes } from "./generate-routes";

export const routes: ILayout[] = [
	{
		layout: AnonymousLayout,
		routes: [
			{
				name: "login",
				title: "Connexion",
				component: LoginPage,
				path: "/login",
				isPublic: true,
			},
		],
	},
	{
		layout: MainLayout,
		routes: [
			{
				name: 'dashboard',
				title: 'Tableau de Bord',
				component: Dashboard,
				exact: true,
				hasSiderLink: true,
				icon: <DashboardOutlined />,
				path: '/',
			},
			{
				name: "/leaves",
				title: "Gestion des congés",
				hasSiderLink: true,
				icon: <ContactsOutlined />,
				routes: [
					{
						name: "request-leaves",
						title: "Demande de congé",
						component: RequestLeavePage,
						exact: true,
						hasSiderLink: true,
						icon: <FormOutlined />,
						path: "/leaves/create",
					},
					{
						name: "list-leaves",
						title: "Liste des congés",
						component: ListLeavesPage,
						exact: true,
						hasSiderLink: true,
						icon: <UnorderedListOutlined />,
						path: "/leaves",
					},
					{
						name: "calendar",
						title: "Calendrier",
						component: CalendarPage,
						exact: true,
						hasSiderLink: true,
						icon: <CalendarOutlined />,
						path: "/calendar",
					},
					{
						name: "update-leaves",
						title: "Modifier la demande de ",
						component: UpdateLeavePage,
						exact: true,
						path: "/leaves/update/:id",
					}
				],
			},
			{
				name: "/users",
				title: "Gestion des utilisateurs",
				hasSiderLink: true,
				icon: <TeamOutlined />,
				path: '/users',
				routes: [
					{
						name: "list-users",
						title: 'Liste des utilisateurs',
						component: ListUsersPage,
						exact: true,
						hasSiderLink: true,
						icon: <TeamOutlined />,
						path: '/users',
					},
					{
						name: "view-users",
						title: 'Profil',
						component: ProfilePage,
						exact: true,
						path: '/users/:id',
					},
					{
						name: "update-users",
						title: 'Mise à jour profil',
						component: UpdateProfilePage,
						exact: true,
						path: '/users/update/:id',
					},
				],
			},
			{
				name: "/departments",
				title: "Départements",
				hasSiderLink: true,
				icon: <BankOutlined />,
				routes: [
					{
						name: "add-departments",
						title: 'Nouveau département',
						component: AddDepartmentPage,
						exact: true,
						hasSiderLink: true,
						icon: <PlusOutlined />,
						path: '/departments/add',
						resources: {
							Department: ["CAN_CREATE"],
						},
					},
					{
						name: "list-departments",
						title: 'Liste des départements',
						component: ListDepartmentsPage,
						exact: true,
						hasSiderLink: true,
						icon: <UnorderedListOutlined />,
						path: '/departments',
					},
					{
						name: "update-departments",
						title: 'Mise à jour département',
						component: UpdateDepartmentPage,
						exact: true,
						path: '/departments/update/:id',
						resources: {
							Department: ["CAN_PUT"],
						},
					},
				],
			},
		],
	},
];

export const Routes = generateRoutes(routes);
