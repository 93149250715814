import { Reducer } from 'redux';
import { IRoleState, RoleActionTypes, ROLES_GET_LIST, ROLES_GET_LIST_ERROR, ROLES_GET_LIST_SUCCESS } from "./types";

export const rolesInitialState: IRoleState = {
  rolesList: [],
  isLoading: false,
  errors: null,
}

export const roleReducer: Reducer<typeof rolesInitialState, RoleActionTypes> = (state = rolesInitialState, action) => {
  switch (action.type) {
    case ROLES_GET_LIST:
      return { ...state, isLoading: true, errors: null };

    case ROLES_GET_LIST_SUCCESS:
      return { ...state, isLoading: false, rolesList: action.payload };

    case ROLES_GET_LIST_ERROR:
      return { ...state, isLoading: false, errors: action.payload };

    default:
      return state;
  };
};