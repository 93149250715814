import { Spin } from 'antd';
import Layout from 'antd/lib/layout/layout';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { EmptyContent } from '../../../components/EmptyContent';
import { PageSkeleton } from '../../../components/PageSkeleton/index';
import { departmentGetById, departmentGetByIdClear, updateDepartment } from '../../../store/department/actions';
import { GlobalState } from '../../../store/root-reducer';
import { getListUsers } from '../../../store/user/actions';
import { hasPermission } from '../../../utils/permissions-utils';
import { IPageProps } from '../../../utils/types/types';
import { UpdateDepartmentForm } from './components/UpdateDepartmentForm';

interface IUpdateDepartmentProps extends IPageProps {

}

const UpdateDepartment: React.FC<IUpdateDepartmentProps & ConnectedProps<typeof connector>> = ({
  history,
  route,
  match,
  departmentData,
  usersData,
  me,
  departmentGetById: departmentGetByIdAction,
  getListUsers: getListUsersAction,
  updateDepartment: updateDepartmentAction,
  departmentGetByIdClear: departmentGetByIdClearAction,
}) => {

  const { resources } = route;
  const { data: thisMe } = me;

  const isPermitted = thisMe && resources && !hasPermission(thisMe.permissions, resources);

  if (!isPermitted) history.push('/');

  const { data: department, isLoading } = departmentData;
  const { data: users, isLoading: usersLoading } = usersData;

  useEffect(() => {
    departmentGetByIdAction(match.params.id);
    getListUsersAction();
  }, [match.params.id, departmentGetByIdAction, getListUsersAction]);

  useEffect(() => {
    return () => {
      departmentGetByIdClearAction();
    };
  }, [departmentGetByIdClearAction]);

  if (!department && !isLoading) return <EmptyContent description="Aucun département n'a été trouvé" />

  return (
    <PageSkeleton mainLayout pageTitle={route.title} goBackTo="/departments">
      <Layout className="content-layout">
        {
          department && (
            <Spin spinning={isLoading}>
              <UpdateDepartmentForm
                department={department}
                users={users}
                usersLoading={usersLoading}
                onFormSubmitted={updateDepartmentAction}
              />
            </Spin>
          )
        }
      </Layout>
    </PageSkeleton>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  departmentData: state.department.department,
  departmentTeam: state.department.departmentTeam,
  usersData: state.users.usersList,
  me: state.users.me,
});

const mapDispatchToProps = {
  departmentGetById,
  getListUsers,
  updateDepartment,
  departmentGetByIdClear,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const UpdateDepartmentPage = connector(UpdateDepartment);
