import { combineReducers } from 'redux';
import { departmentInitialState, departmentReducer } from './department/reducers';
import { DepartmentActionTypes } from './department/types';
import { leavesInitialState, leavesReducer } from './leave/reducers';
import { leavesActionType } from './leave/types';
import { roleReducer, rolesInitialState } from './role/reducers';
import { RoleActionTypes } from './role/types';
import { usersInitialState, usersReducer } from './user/reducers';
import { UsersActionTypes, USER_LOGGED_OUT } from './user/types';

const appReducer = combineReducers({
  department: departmentReducer,
  leaves: leavesReducer,
  roles: roleReducer,
  users: usersReducer,
});

const rootReducerInitialState = {
  department: departmentInitialState,
  leaves: leavesInitialState,
  roles: rolesInitialState,
  users: usersInitialState,
};

// Define GlobalState Type
export type GlobalState = ReturnType<typeof appReducer>;

type actionTypes = DepartmentActionTypes | leavesActionType | RoleActionTypes | UsersActionTypes;

const rootReducer = (state: GlobalState = rootReducerInitialState, action: actionTypes) => {
  if (action.type === USER_LOGGED_OUT) return rootReducerInitialState;
  return appReducer(state, action);
};

export default rootReducer;
