import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { PageSkeleton } from "../../../components/PageSkeleton";
import { getDepartmentList, getDepartmentTeamList, updateDepartment } from '../../../store/department/actions';
import { GlobalState } from "../../../store/root-reducer";
import { getListUsers } from "../../../store/user/actions";
import { ICreateDepartment, IDepartment } from "../../../utils/types/department";
import { IPageProps } from "../../../utils/types/types";
import { ListDepartments } from "./components/ListDepartments";
import { ModalUpdateDepartment } from "./components/ModalUpdateDepartment";

interface IListDepartmentsPageProps extends IPageProps { }

const ListDepartmentsPageInner: React.FC<IListDepartmentsPageProps & ConnectedProps<typeof connector>> = ({
  route,
  departments,
  departmentTeam,
  users,
  me,
  getDepartmentList: getDepartmentListAction,
  getDepartmentTeamList: getDepartmentTeamListAction,
  getListUsers: getListUsersAction,
  updateDepartment: updateDepartmentAction,
}) => {


  const [isDepartmentModalVisible, setDepartmentModalVisible] = useState(false);
  const [currentDepartment, setCurrentDepartment] = useState<IDepartment | undefined>();

  useEffect(() => {
    setCurrentDepartment(departments[0]);
  }, [departments]);

  useEffect(() => {
    getListUsersAction();
  }, [getListUsersAction]);

  useEffect(() => {
    getDepartmentListAction();
  }, [getDepartmentListAction]);


  const getDepartment = (departmentsList: IDepartment[], idDepartment: string) => departmentsList.find(({ id }) => id === idDepartment);

  useEffect(() => {
    if (departments.data.length) {
      getDepartmentTeamListAction(departments.data[0].id);
      setCurrentDepartment(getDepartment(departments.data, departments.data[0].id));
    }
  }, [departments]);

  const handleTabChange = (id: string) => {
    getDepartmentTeamListAction(id);
    setCurrentDepartment(getDepartment(departments.data, id));
  };

  const onUpdateDepartmentFormSubmitted = (values: ICreateDepartment) => {
    updateDepartmentAction(values)
    setDepartmentModalVisible(false);
    setCurrentDepartment(getDepartment(departments.data, values.id));
  };

  return (
    <PageSkeleton mainLayout={true} pageTitle={route.title}>
      <ListDepartments
        departments={departments.data}
        team={departmentTeam.data}
        onDepartmentTabChange={handleTabChange}
        onUpdateModalVisibility={setDepartmentModalVisible}
        isLoading={departmentTeam.isLoading}
        me={me}
      />
      {
        !!currentDepartment && (
          <ModalUpdateDepartment
            department={currentDepartment}
            users={users.data}
            isModalVisible={isDepartmentModalVisible}
            onCloseModal={setDepartmentModalVisible}
            onUpdateFormSubmited={onUpdateDepartmentFormSubmitted}
          />
        )
      }

    </PageSkeleton>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  departments: state.department.departmentList,
  departmentTeam: state.department.departmentTeam,
  users: state.users.usersList,
  me: state.users.me,
});

const mapDispatchToProps = {
  getDepartmentList,
  getDepartmentTeamList,
  getListUsers,
  updateDepartment,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ListDepartmentsPage = connector(ListDepartmentsPageInner);
