import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { EmptyContent } from "../../../components/EmptyContent";
import { PageSkeleton } from "../../../components/PageSkeleton";
import { AuthContext } from '../../../context/AuthContext/index';
import { getMyLeaves, leaveDecisionUpdate, leaveGetList } from '../../../store/leave/actions';
import { IListLeavesFilter } from "../../../store/leave/types";
import { GlobalState } from "../../../store/root-reducer";
import { ILeaveDecision } from "../../../utils/types/leave";
import { IAuthContext, IPageProps } from "../../../utils/types/types";
import { CustomTabs } from "./components/CustomTabs";
import { LeavesFilters } from "./components/LeavesFilters";
import { ListLeaves } from "./components/ListLeaves";


interface IListLeavesPageProps extends IPageProps { }

export const ListLeavesPageInner: React.FC<IListLeavesPageProps & ConnectedProps<typeof connector>> = ({
  route,
  myLeaves,
  leaves,
  me: connectedUser,
  updateLeaveDecision,
  getMyLeaves: getMyLeavesAction,
  leaveGetList: leaveGetListAction,
  leaveDecisionUpdate: leaveDecisionUpdateAction,
}) => {

  const { state }: IAuthContext = useContext(AuthContext);

  const { data: me } = connectedUser;

  const [currentTab, setCurrentTab] = useState<'me' | 'team'>('me');

  useEffect(() => {
    if (state.token && me) getMyLeavesAction({ id: me.id });
  }, [me, getMyLeavesAction, state.token]);

  /**
   * Handle custom tabs
   */
  const handleCustomTabChange = (seletedTab: 'me' | 'team') => {
    setCurrentTab(seletedTab);
    me && seletedTab === 'me' ? getMyLeavesAction({ id: me.id }) : leaveGetListAction();
  };

  const handleUpdatedLeaveDecision = (updateLeaveDecision: ILeaveDecision) => {
    leaveDecisionUpdateAction(updateLeaveDecision);
  };

  const handleLeavesFilterChange = (filters?: IListLeavesFilter) => {
    if (me && currentTab === 'me') getMyLeavesAction({
      ...filters,
      id: me.id,
    });
    else if (me && me.role.slug === "ROLE_MANAGER") leaveGetListAction(filters);
  }

  if (!me) return null;

  if (!leaves.data) return <EmptyContent description="Aucune demande de congé" />;

  return (
    <PageSkeleton pageTitle={route.title} mainLayout>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <LeavesFilters onFiltersChange={handleLeavesFilterChange} />
        </Col>
      </Row>

      <Row gutter={[8, 16]}>
        <Col span={24}>
          <CustomTabs isResponsible={me.isResponsible || me.role.slug === "ROLE_MANAGER"} onTabChange={handleCustomTabChange} />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ListLeaves leaves={currentTab === 'me' ? myLeaves : leaves} isMe={currentTab === 'me'} role={me.role.slug} onUpdatedLeaveDecision={handleUpdatedLeaveDecision} leaveDecision={updateLeaveDecision} />
        </Col>
      </Row>
    </PageSkeleton>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  myLeaves: state.leaves.myLeaves,
  leaves: state.leaves.list,
  me: state.users.me,
  updateLeaveDecision: state.leaves.leaveDecisionUpdate,
});

const mapDispatchToProps = {
  getMyLeaves,
  leaveGetList,
  leaveDecisionUpdate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ListLeavesPage = connector(ListLeavesPageInner);
