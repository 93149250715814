import { Modal } from 'antd';
import React from 'react';
import { UserPreview } from '../../../../../components/UserPreview';
import { IUser } from '../../../../../utils/types/user';

interface IModalDetailsPreviewProps {
  user: IUser | null;
  isVisible: boolean;
  onCloseModal: (state: boolean) => void;
}

export const ModalDetailsPreview: React.FC<IModalDetailsPreviewProps> = ({
  user,
  isVisible,
  onCloseModal,
}) => {

  const handleClose = () => onCloseModal(false);

  return (
    <div>
      <Modal
        title="Détails utilisateur"
        style={{ top: 20 }}
        visible={isVisible}
        onCancel={handleClose}
        footer={null}
      >
        <UserPreview user={user} bordered />
      </Modal>
    </div>
  )
}
