import { Action } from "redux";
import { ICreateLeave, IGetMyLeavesWithFilters, ILeave, ILeaveDecision, ILeaveType } from "../../utils/types/leave";
import { IErrors, IStateInitializer } from "../../utils/types/types";

/* ================= types ================= */
export interface IAddLeave extends IStateInitializer {
  isCreated: boolean;
};

export interface IUpdateLeaveDecision extends IStateInitializer {
  isUpdated: boolean;
};

export interface ILeavesList extends IStateInitializer {
  data: ILeave[];
}

export interface ILeaveById extends IStateInitializer {
  data: ILeave | null;
}

export interface ILeaveTypes extends IStateInitializer {
  data: ILeaveType[];
};

export interface IListLeavesFilter {
  filterBy: string;
  filterValue: string;
};

export interface ILeaveState {
  myLeaves: ILeavesList;
  list: ILeavesList;
  leave: ILeaveById;
  addLeave: IAddLeave;
  update: IStateInitializer;
  cancel: IStateInitializer;
  leaveDecisionUpdate: IUpdateLeaveDecision;
  leaveTypes: ILeaveTypes;
};
/* ================= types ================= */

/**
 * Add leave request
 */
export const LEAVE_ADD_REQUEST = 'LEAVE_ADD_REQUEST';
export type LEAVE_ADD_REQUEST = typeof LEAVE_ADD_REQUEST;

export const LEAVE_ADD_REQUEST_SUCCESS = 'LEAVE_ADD_REQUEST_SUCCESS';
export type LEAVE_ADD_REQUEST_SUCCESS = typeof LEAVE_ADD_REQUEST_SUCCESS;

export const LEAVE_ADD_REQUEST_ERROR = 'LEAVE_ADD_REQUEST_ERROR';
export type LEAVE_ADD_REQUEST_ERROR = typeof LEAVE_ADD_REQUEST_ERROR;

export const LEAVE_ADD_REQUEST_RESET_CREATED = 'LEAVE_ADD_REQUEST_RESET_CREATED';
export type LEAVE_ADD_REQUEST_RESET_CREATED = typeof LEAVE_ADD_REQUEST_RESET_CREATED;

export interface ILeaveAddRequestAction extends Action<LEAVE_ADD_REQUEST> {
  payload: ICreateLeave;
};

export interface ILeaveAddRequestSuccessAction extends Action<LEAVE_ADD_REQUEST_SUCCESS> { };

export interface ILeaveAddRequestErrorAction extends Action<LEAVE_ADD_REQUEST_ERROR> {
  payload: IErrors;
};

export interface ILeaveAddRequestResetCreatedAction extends Action<LEAVE_ADD_REQUEST_RESET_CREATED> { };

/**
 * Get List leaves types
 */
export const LEAVES_TYPES_GET_LIST = 'LEAVES_TYPES_GET_LIST';
export type LEAVES_TYPES_GET_LIST = typeof LEAVES_TYPES_GET_LIST;

export const LEAVES_TYPES_GET_LIST_SUCCESS = 'LEAVES_TYPES_GET_LIST_SUCCESS';
export type LEAVES_TYPES_GET_LIST_SUCCESS = typeof LEAVES_TYPES_GET_LIST_SUCCESS;

export const LEAVES_TYPES_GET_LIST_ERROR = 'LEAVES_TYPES_GET_LIST_ERROR';
export type LEAVES_TYPES_GET_LIST_ERROR = typeof LEAVES_TYPES_GET_LIST_ERROR;

export interface ILeavesTypesGetListAction extends Action<LEAVES_TYPES_GET_LIST> { };

export interface ILeavesTypesGetListSuccessAction extends Action<LEAVES_TYPES_GET_LIST_SUCCESS> {
  payload: ILeaveType[];
};

export interface ILeavesTypesGetListErrorAction extends Action<LEAVES_TYPES_GET_LIST_ERROR> {
  payload: IErrors;
};

/**
 * Get List leaves
 */
export const LEAVE_GET_LIST = 'LEAVE_GET_LIST';
export type LEAVE_GET_LIST = typeof LEAVE_GET_LIST;

export const LEAVE_GET_LIST_SUCCESS = 'LEAVE_GET_LIST_SUCCESS';
export type LEAVE_GET_LIST_SUCCESS = typeof LEAVE_GET_LIST_SUCCESS;

export const LEAVE_GET_LIST_ERROR = 'LEAVE_GET_LIST_ERROR';
export type LEAVE_GET_LIST_ERROR = typeof LEAVE_GET_LIST_ERROR;

export interface ILeaveGetListAction extends Action<LEAVE_GET_LIST> {
  payload?: IListLeavesFilter;
};

export interface ILeaveGetListSuccessAction extends Action<LEAVE_GET_LIST_SUCCESS> {
  payload: ILeave[];
};

export interface ILeaveGetListErrorAction extends Action<LEAVE_GET_LIST_ERROR> {
  payload: IErrors;
};

/**
 * Get Leave by ID
 */
export const LEAVE_GET_BY_ID = 'LEAVE_GET_BY_ID';
export type LEAVE_GET_BY_ID = typeof LEAVE_GET_BY_ID;

export const LEAVE_GET_BY_ID_SUCCESS = 'LEAVE_GET_BY_ID_SUCCESS';
export type LEAVE_GET_BY_ID_SUCCESS = typeof LEAVE_GET_BY_ID_SUCCESS;

export const LEAVE_GET_BY_ID_ERROR = 'LEAVE_GET_BY_ID_ERROR';
export type LEAVE_GET_BY_ID_ERROR = typeof LEAVE_GET_BY_ID_ERROR;

export const LEAVE_GET_BY_ID_CLEAR = 'LEAVE_GET_BY_ID_CLEAR';
export type LEAVE_GET_BY_ID_CLEAR = typeof LEAVE_GET_BY_ID_CLEAR;

export interface ILeaveGetByIdAction extends Action<LEAVE_GET_BY_ID> {
  payload: string;
};

export interface ILeaveGetByIdSuccessAction extends Action<LEAVE_GET_BY_ID_SUCCESS> {
  payload: ILeave;
};

export interface ILeaveGetByIdErrorAction extends Action<LEAVE_GET_BY_ID_ERROR> {
  payload: IErrors;
};

export interface ILeaveGetByIdClearAction extends Action<LEAVE_GET_BY_ID_CLEAR> { };

/**
 * Update Leave
 */
export const LEAVE_UPDATE = 'LEAVE_UPDATE';
export type LEAVE_UPDATE = typeof LEAVE_UPDATE;

export const LEAVE_UPDATE_SUCCESS = 'LEAVE_UPDATE_SUCCESS';
export type LEAVE_UPDATE_SUCCESS = typeof LEAVE_UPDATE_SUCCESS;

export const LEAVE_UPDATE_ERROR = 'LEAVE_UPDATE_ERROR';
export type LEAVE_UPDATE_ERROR = typeof LEAVE_UPDATE_ERROR;

export interface ILeaveUpdateAction extends Action<LEAVE_UPDATE> {
  payload: ICreateLeave;
};

export interface ILeaveUpdateSuccessAction extends Action<LEAVE_UPDATE_SUCCESS> { };

export interface ILeaveUpdateErrorAction extends Action<LEAVE_UPDATE_ERROR> {
  payload: IErrors;
};

/**
 * Cancel leave
 */
export const LEAVE_CANCEL = 'LEAVE_CANCEL';
export type LEAVE_CANCEL = typeof LEAVE_CANCEL;

export const LEAVE_CANCEL_SUCCESS = 'LEAVE_CANCEL_SUCCESS';
export type LEAVE_CANCEL_SUCCESS = typeof LEAVE_CANCEL_SUCCESS;

export const LEAVE_CANCEL_ERROR = 'LEAVE_CANCEL_ERROR';
export type LEAVE_CANCEL_ERROR = typeof LEAVE_CANCEL_ERROR;

export interface ILeaveCancelAction extends Action<LEAVE_CANCEL> {
  payload: string;
};

export interface ILeaveCancelSuccessAction extends Action<LEAVE_CANCEL_SUCCESS> { };

export interface ILeaveCancelErrorAction extends Action<LEAVE_CANCEL_ERROR> {
  payload: IErrors;
};

/**
 * Update Leave Decision
 */
export const LEAVE_DECISION_UPDATE = 'LEAVE_DECISION_UPDATE';
export type LEAVE_DECISION_UPDATE = typeof LEAVE_DECISION_UPDATE;

export const LEAVE_DECISION_UPDATE_SUCCESS = 'LEAVE_DECISION_UPDATE_SUCCESS';
export type LEAVE_DECISION_UPDATE_SUCCESS = typeof LEAVE_DECISION_UPDATE_SUCCESS;

export const LEAVE_DECISION_UPDATE_ERROR = 'LEAVE_DECISION_UPDATE_ERROR';
export type LEAVE_DECISION_UPDATE_ERROR = typeof LEAVE_DECISION_UPDATE_ERROR;

export const LEAVE_DECISION_UPDATE_RESET = 'LEAVE_DECISION_UPDATE_RESET';
export type LEAVE_DECISION_UPDATE_RESET = typeof LEAVE_DECISION_UPDATE_RESET;

export interface ILeaveDecisionUpdateAction extends Action<LEAVE_DECISION_UPDATE> {
  payload: ILeaveDecision;
};

export interface ILeaveDecisionUpdateSuccessAction extends Action<LEAVE_DECISION_UPDATE_SUCCESS> { };

export interface ILeaveDecisionUpdateErrorAction extends Action<LEAVE_DECISION_UPDATE_ERROR> {
  payload: IErrors;
};

export interface ILeaveDecisionUpdateResetAction extends Action<LEAVE_DECISION_UPDATE_RESET> { };

/**
 * Get My Leaves
 */
export const GET_MY_LEAVES = 'GET_MY_LEAVES';
export type GET_MY_LEAVES = typeof GET_MY_LEAVES;

export const GET_MY_LEAVES_SUCCESS = 'GET_MY_LEAVES_SUCCESS';
export type GET_MY_LEAVES_SUCCESS = typeof GET_MY_LEAVES_SUCCESS;

export const GET_MY_LEAVES_ERROR = 'GET_MY_LEAVES_ERROR';
export type GET_MY_LEAVES_ERROR = typeof GET_MY_LEAVES_ERROR;

export interface IGetMyLeavesAction extends Action<GET_MY_LEAVES> {
  payload: IGetMyLeavesWithFilters;
};

export interface IGetMyLeavesSuccessAction extends Action<GET_MY_LEAVES_SUCCESS> {
  payload: ILeave[];
};

export interface IGetMyLeavesErrorAction extends Action<GET_MY_LEAVES_ERROR> {
  payload: IErrors;
};

export type leavesActionType =
  ILeavesTypesGetListAction
  | ILeavesTypesGetListSuccessAction
  | ILeavesTypesGetListErrorAction
  | ILeaveAddRequestAction
  | ILeaveAddRequestSuccessAction
  | ILeaveAddRequestErrorAction
  | ILeaveAddRequestResetCreatedAction
  | ILeaveGetListAction
  | ILeaveGetListSuccessAction
  | ILeaveGetListErrorAction
  | ILeaveGetByIdAction
  | ILeaveGetByIdSuccessAction
  | ILeaveGetByIdErrorAction
  | ILeaveGetByIdClearAction
  | ILeaveUpdateAction
  | ILeaveUpdateSuccessAction
  | ILeaveUpdateErrorAction
  | ILeaveCancelAction
  | ILeaveCancelSuccessAction
  | ILeaveCancelErrorAction
  | ILeaveDecisionUpdateAction
  | ILeaveDecisionUpdateSuccessAction
  | ILeaveDecisionUpdateErrorAction
  | ILeaveDecisionUpdateResetAction
  | IGetMyLeavesAction
  | IGetMyLeavesSuccessAction
  | IGetMyLeavesErrorAction;
