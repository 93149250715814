import React from 'react';
import { PageSkeleton } from '../../components/PageSkeleton/index';
import { useWindowResize } from '../../hooks/index';
import { IPageProps } from '../../utils/types/types';
import { ListLeavesBalance } from './components/ListLeavesBalance';

interface IDashboardProps extends IPageProps {

}

export const Dashboard: React.FC<IDashboardProps> = ({ route }) => {

  const { isMobile } = useWindowResize();

  return (
    <PageSkeleton mainLayout={true} pageTitle={route.title}>
      {isMobile && <ListLeavesBalance />}
    </PageSkeleton>
  )
}
