import React from "react";
import { Link } from "react-router-dom";
import "./logo-styles.less";

interface ILogoProps {
	location?: "sider" | "block";
}

export const Logo: React.FC<ILogoProps> = ({ location = 'sider' }) => (
	<div
		className={
			`logo-wrapper
			${location === 'sider' ? 'logo-sider-padding' : ''}`
		}
	>
		<Link to={location === 'sider' ? '/' : '#'}>
			<img src='/images/logo.png' className='logo' alt='Symdrik-RH | logo' />
		</Link>
	</div>
);
