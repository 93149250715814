import { IRoutePermissions } from "./types/types";
import { IUserPermissions } from "./types/user";

/**
 * Check if the connected user has a resource &
 * permission that matches route's resourse and permissions
 */
export const hasPermission = (userPermissions: IUserPermissions, routePermissions: IRoutePermissions) => {
  for (let key in routePermissions) {
    const routePermission = routePermissions[key];
    const userPermission = userPermissions[key];
    if (!userPermission) return false;
    return !!userPermission.filter(perm => {
      return perm.some(subPerm => subPerm && routePermission.includes(subPerm));
    });
  }
};