import { ReloadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row, Select, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { IListLeavesFilter } from '../../../../../store/leave/types';
import './filters.less';

const { Option } = Select;
const { Text } = Typography;
const { Item } = Form;

interface ILeavesFiltersProps {
  onFiltersChange: (filters?: IListLeavesFilter) => void
}

export const LeavesFilters: React.FC<ILeavesFiltersProps> = ({
  onFiltersChange,
}) => {

  const [form] = Form.useForm();

  const [status, setStatus] = useState<IListLeavesFilter | undefined>(undefined);

  useEffect(() => {
    if (status) onFiltersChange(status);
  }, [status]);

  const handleStatusChange = (val: string) => setStatus({
    filterBy: 'status',
    filterValue: val,
  });

  const handleResetFilters = () => {
    if (status) {
      onFiltersChange();
      form.resetFields();
    }
  };

  return (
    <Card bordered={false}>
      <Form
        form={form}
        layout="horizontal"
      >
        <Row gutter={[16, 16]} align="bottom">
          <Col md={8} sm={16} xs={20}>
            <Item name="status" label="Statut">
              <Select placeholder="-- Statut --" onChange={handleStatusChange}>
                <Option value="null">En attente</Option>
                <Option value="archived">Fermées</Option>
              </Select>
            </Item>
          </Col>
          <Col md={16} sm={8} xs={4}>
            <Item>
              <Tooltip title="Réinitialiser le filtre">
                <Button icon={<ReloadOutlined />} danger onClick={handleResetFilters} />
              </Tooltip>
            </Item>
          </Col>
        </Row>


      </Form>
    </Card>
  );
};
