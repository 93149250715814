import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DesktopMode, MobileMode } from "../../../components/DeviceDetector";
import { PageSkeleton } from "../../../components/PageSkeleton";
import { UserPreview } from "../../../components/UserPreview";
import { AuthContext } from "../../../context/AuthContext";
import { useWindowResize } from '../../../hooks/index';
import { GlobalState } from "../../../store/root-reducer";
import { getListUsers } from "../../../store/user/actions";
import { IAuthContext, IPageProps } from "../../../utils/types/types";
import { IUser } from "../../../utils/types/user";
import { ListUsers } from "./components/ListUsers";
import { ModalDetailsPreview } from './components/ModalDetailsPreview/index';

interface IListUsersPageProps extends IPageProps { }

const ListUsersInner: React.FC<IListUsersPageProps & ConnectedProps<typeof connector>> = ({
	route,
	users,
	me,
	getListUsers: getListUsersAction
}) => {

	const { state }: IAuthContext = useContext(AuthContext);

	const { isMobile } = useWindowResize();

	const [isUserModalVisible, setuserMidalVisible] = useState(false);
	const [user, setUser] = useState<IUser | null>(null);

	useEffect(() => {
		if (state.token) getListUsersAction();
	}, [getListUsersAction, state.token]);

	useEffect(() => {
		if (users.data.length && !isMobile) setUser(users.data[0]);
	}, [users]);

	useEffect(() => {
		if (user) setuserMidalVisible(true);
	}, [user]);

	useEffect(() => {
		if (!isUserModalVisible) setUser(null);
	}, [isUserModalVisible]);

	return (
		<PageSkeleton mainLayout={true} pageTitle={route.title}>
			<Row gutter={16}>
				<Col lg={19} xs={24}>
					{
						me && <ListUsers users={users.data} onSelectUser={setUser} me={me} />
					}
				</Col>
				<Col span={5}>
					<DesktopMode>
						<UserPreview isPreview user={user} />
					</DesktopMode>
					<MobileMode>
						<ModalDetailsPreview user={user} isVisible={isUserModalVisible} onCloseModal={setuserMidalVisible} />
					</MobileMode>
				</Col>
			</Row>
		</PageSkeleton>
	);
};

const mapStateToProps = (state: GlobalState) => ({
	users: state.users.usersList,
	me: state.users.me,
});

const mapDispatchToProps = {
	getListUsers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ListUsersPage = connector(ListUsersInner);
