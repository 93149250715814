import { ICreateDepartment, IDepartment } from "../../utils/types/department";
import { IErrors } from "../../utils/types/types";
import { IUser } from "../../utils/types/user";
import { DEPARTMENT_ADD_NEW, DEPARTMENT_ADD_NEW_ERROR, DEPARTMENT_ADD_NEW_SUCCESS, DEPARTMENT_GET_BY_ID, DEPARTMENT_GET_BY_ID_CLEAR, DEPARTMENT_GET_BY_ID_ERROR, DEPARTMENT_GET_BY_ID_SUCCESS, DEPARTMENT_GET_LIST, DEPARTMENT_GET_LIST_ERROR, DEPARTMENT_GET_LIST_SUCCESS, DEPARTMENT_TEAM_GET_LIST, DEPARTMENT_TEAM_GET_LIST_ERROR, DEPARTMENT_TEAM_GET_LIST_SUCCESS, DEPARTMENT_UPDATE, DEPARTMENT_UPDATE_ERROR, DEPARTMENT_UPDATE_SUCCESS, IDepartmentAddAction, IDepartmentAddErrorAction, IDepartmentAddSuccessAction, IDepartmentGetByIdAtion, IDepartmentGetByIdClearAtion, IDepartmentGetByIdErrorAtion, IDepartmentGetByIdSuccessAtion, IGetDepartmentListAction, IGetDepartmentListErrorAction, IGetDepartmentListSuccessAction, IGetDepartmentTeamListAction, IGetDepartmentTeamListErrorAction, IGetDepartmentTeamListSuccessAction, IUpdateDepartmentAction, IUpdateDepartmentErrorAction, IUpdateDepartmentSuccessAction } from "./types";

/**
 * Add New Department
 */
export const addDepartment = (department: ICreateDepartment): IDepartmentAddAction => ({
  type: DEPARTMENT_ADD_NEW,
  payload: department,
});

export const addDepartmentSuccess = (): IDepartmentAddSuccessAction => ({
  type: DEPARTMENT_ADD_NEW_SUCCESS,
});

export const addDepartmentError = (errors: IErrors): IDepartmentAddErrorAction => ({
  type: DEPARTMENT_ADD_NEW_ERROR,
  payload: errors,
});

/**
 * List Departments
 */
export const getDepartmentList = (): IGetDepartmentListAction => ({
  type: DEPARTMENT_GET_LIST,
});

export const getDepartmentListSuccess = (departments: IDepartment[]): IGetDepartmentListSuccessAction => ({
  type: DEPARTMENT_GET_LIST_SUCCESS,
  payload: departments,
});

export const getDepartmentListError = (errors: IErrors): IGetDepartmentListErrorAction => ({
  type: DEPARTMENT_GET_LIST_ERROR,
  payload: errors,
});

/**
 * List Users Department
 */
export const getDepartmentTeamList = (id: string): IGetDepartmentTeamListAction => ({
  type: DEPARTMENT_TEAM_GET_LIST,
  payload: id,
});

export const getDepartmentTeamListSuccess = (users: IUser[]): IGetDepartmentTeamListSuccessAction => ({
  type: DEPARTMENT_TEAM_GET_LIST_SUCCESS,
  payload: users,
});

export const getDepartmentTeamListError = (errors: IErrors): IGetDepartmentTeamListErrorAction => ({
  type: DEPARTMENT_TEAM_GET_LIST_ERROR,
  payload: errors,
});

/**
 * Update Department
 */
export const updateDepartment = (department: ICreateDepartment): IUpdateDepartmentAction => ({
  type: DEPARTMENT_UPDATE,
  payload: department,
});

export const updateDepartmentSuccess = (): IUpdateDepartmentSuccessAction => ({
  type: DEPARTMENT_UPDATE_SUCCESS,
});

export const updateDepartmentError = (errors: IErrors): IUpdateDepartmentErrorAction => ({
  type: DEPARTMENT_UPDATE_ERROR,
  payload: errors,
});

/**
 * Get Department by ID
 */
export const departmentGetById = (id: string): IDepartmentGetByIdAtion => ({
  type: DEPARTMENT_GET_BY_ID,
  payload: id,
});

export const departmentGetByIdSuccess = (department: IDepartment): IDepartmentGetByIdSuccessAtion => ({
  type: DEPARTMENT_GET_BY_ID_SUCCESS,
  payload: department,
});

export const departmentGetByIdError = (errors: IErrors): IDepartmentGetByIdErrorAtion => ({
  type: DEPARTMENT_GET_BY_ID_ERROR,
  payload: errors,
});

export const departmentGetByIdClear = (): IDepartmentGetByIdClearAtion => ({
  type: DEPARTMENT_GET_BY_ID_CLEAR,
});
