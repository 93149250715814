import { Layout, PageHeader } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../PageTitle";

interface IPageSkeletonProps {
	mainLayout?: boolean; // is a main layout
	pageTitle: string; // page that will be displayed as page and page header title
	goBackTo?: string; // url want to go back to
}

export const PageSkeleton: React.FC<IPageSkeletonProps> = ({
	children,
	mainLayout = true,
	pageTitle,
	goBackTo,
}) => {
	const history = useHistory();
	return (
		<Layout>
			<PageTitle title={pageTitle} />
			{mainLayout && (
				<PageHeader
					className='site-page-header'
					title={pageTitle}
					onBack={goBackTo ? () => history.push(goBackTo) : undefined}
				/>
			)}
			{children}
		</Layout>
	);
};
