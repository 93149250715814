import { Col, Divider, Form, Layout, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { leaveAddRequest, leaveAddRequestResetCreated, leaveTypesGetList } from '../../../store/leave/actions';
import { GlobalState } from '../../../store/root-reducer';
import { ICreateLeave, ILeaveType } from '../../../utils/types/leave';
import { IPageProps } from '../../../utils/types/types';
import { exceptionalLeave } from '../../../utils/vars';
import { ExceptionalLeaveForm } from './components/ExceptionalLeaveForm';
import { LeaveForm } from './components/LeaveForm';

const { Option } = Select;
const { Item } = Form;

interface IRequestLeavePageProps extends IPageProps { };

export const RequestLeavePageInner: React.FC<IRequestLeavePageProps & ConnectedProps<typeof connector>> = ({
  route,
  addLeave,
  leaveTypes,
  me,
  leaveTypesGetList: leaveTypesGetListAction,
  leaveAddRequest: leaveAddRequestAction,
  leaveAddRequestResetCreated: leaveAddRequestResetCreatedAction,
}) => {

  useEffect(() => {
    leaveTypesGetListAction();
  }, [leaveTypesGetListAction]);

  useEffect(() => {
    return () => {
      leaveAddRequestResetCreatedAction();
    };
  }, []);

  const [form] = Form.useForm();

  const { data: leaveTypesList, isLoading } = leaveTypes;

  const [selectedLeaveId, setSelectedLeaveId] = useState<string | null>(null);
  const [selectedLeaveType, setSelectedLeaveType] = useState<ILeaveType | null>(null);

  useEffect(() => {
    if (addLeave.isCreated) form.resetFields();
  }, [addLeave.isCreated]);

  // find leave by ID
  const getLeaveTypeById = (leaves: ILeaveType[], currentLeaveId: string) => leaves.find(({ id }) => id === currentLeaveId) || null;

  const handleLeaveTypeChange = (id: string) => {
    setSelectedLeaveId(id);
    const leave = getLeaveTypeById(leaveTypesList, id);
    if (leave) setSelectedLeaveType(leave);
  };

  const handleLeave = (leave: ICreateLeave) => me && leaveAddRequestAction({
    ...leave,
    type: `/leave_types/${selectedLeaveId}`,
  });

  return (
    <PageSkeleton mainLayout={true} pageTitle={route.title}>
      <Layout className="content-layout">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Divider orientation="left">Type de demande</Divider>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col md={8} xs={24}>
            <Form
              form={form}
            >
              <Item
                name="type"
              >
                <Select
                  loading={isLoading}
                  placeholder="Sélectionner le type de congé"
                  onChange={handleLeaveTypeChange}
                >
                  {
                    leaveTypesList.map((type: ILeaveType) => <Option key={type.id} value={type.id}>{type.name}</Option>)
                  }
                </Select>
              </Item>
            </Form>
          </Col>
        </Row>
        {/* 
          Note: The code below is a ternary operator with two levels:
          1- Check if there is a selected leave type so a form will be rendered or do not render
          2- check which type is selected to switch between
        */}
        {selectedLeaveType ? selectedLeaveType.name.toLowerCase() === exceptionalLeave ? <ExceptionalLeaveForm onSubmitLeave={handleLeave} addLeave={addLeave} /> : <LeaveForm onSubmitLeave={handleLeave} addLeave={addLeave} /> : null}
      </Layout>
    </PageSkeleton>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  leaveTypes: state.leaves.leaveTypes,
  addLeave: state.leaves.addLeave,
  me: state.users.me.data,
});

const mapDispatchToProps = {
  leaveTypesGetList,
  leaveAddRequest,
  leaveAddRequestResetCreated,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const RequestLeavePage = connector(RequestLeavePageInner);
