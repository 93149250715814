import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { ICreateDepartment, IDepartment } from "../../../../../utils/types/department";
import { IUser } from "../../../../../utils/types/user";

interface IAddFormProps {
  department: IDepartment;
  users: IUser[];
  usersLoading: boolean;
  onFormSubmitted: (department: ICreateDepartment) => void;
}

const { Item } = Form;
const { TextArea } = Input;
const { Option } = Select;

export const UpdateDepartmentForm: React.FC<IAddFormProps> = ({
  department,
  users,
  usersLoading,
  onFormSubmitted,
}) => {

  const [form] = Form.useForm();

  const handleUpdateFormSubmited = (values: any) => {
    if (values) onFormSubmitted({
      ...values,
      id: department.id,
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleUpdateFormSubmited}
      initialValues={department}
    >

      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Item
            name="title"
            label="Titre"
            rules={[
              { required: true, message: 'Veuillez saisir un nom de département !' }
            ]}
          >
            <Input type="text" placeholder="Titre" />
          </Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Item
            name="description"
            label="Description"
          >
            <TextArea placeholder="Description" />
          </Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Item name="responsible" label="Choisir un chef de pôle">
            <Select placeholder="Choisir un chef de pôle pour ce département" loading={usersLoading}>
              {
                users.map(user => <Option key={user.id} value={`/users/${user.id}`}>{user.firstName} {user.lastName}</Option>)
              }
            </Select>
          </Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Item>
            <Button type="primary" block htmlType="submit">Modifier</Button>
          </Item>
        </Col>
      </Row>

    </Form>
  );
};
