import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { parseAxiosError } from '../utils/axios-utils';
import { getTokenPackage, isValidToken } from '../utils/token-utils';

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Accept': 'application/json',
  },
});

/**
 * Handle Axios requests and put auth token in header
 */
export const handleAxiosAuth = (authToken: string, logout: () => void) => {
  const tokenPack = getTokenPackage();
  const isTokenValid = tokenPack && tokenPack.expiresAt && isValidToken(tokenPack.expiresAt);
  if (isTokenValid) api.interceptors.request.use(
    (request: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
      if (request.url !== '/login') request.headers.Authorization = `Bearer ${authToken}`;
      return request;
    },
    error => {
      return Promise.reject(error);
    }
  );
  else logout();
};

/**
 * Handle Axios response errors
 */
export const handleAxiosErrors = () => {
  api.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
      return response;
    },
    error => {
      return Promise.reject(parseAxiosError(error));
    }
  );
};
