import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router-dom";
import { GlobalState } from "../../store/root-reducer";
import { userLoggedOut } from "../../store/user/actions";
import { AppContent } from "./components/AppContent";
import { AppHeader } from "./components/AppHeader";
import { AppSider } from "./components/AppSider";

interface IMainLayoutProps { }

const MainLayoutInner: React.FC<IMainLayoutProps & ConnectedProps<typeof connector>> = ({
	children,
	me,
	userLoggedOut: userLoggedOutAction,
}) => {

	const history = useHistory();
	const [menuToggled, setMenuToggled] = useState(false);
	const [selectedKey, setSelectedKey] = useState('/');

	useEffect(() => {
		const path = history.location.pathname;
		const formattedPath = removePossibleIds(path).join('/');
		setSelectedKey(formattedPath);
	}, [history.location.pathname]);

	const handelMenuToggled = () => setMenuToggled(!menuToggled);
	const onClose = () => {
		setMenuToggled(false);
	};

	const removePossibleIds = (pathname: string) => {
		const urlFragments = pathname.split('/');
		const hasNumbers = /\d/;
		return urlFragments.filter(f => !hasNumbers.test(f));
	};

	return (
		<Layout className='full-height'>

			<AppSider
				me={me}
				menuToggled={menuToggled}
				onCloseDrawer={onClose}
				selectedKey={selectedKey}
			/>

			<Layout>
				<AppHeader
					menuToggled={menuToggled}
					handelMenuToggled={handelMenuToggled}
					onLogoutClick={userLoggedOutAction}
				/>

				<AppContent>{children}</AppContent>
			</Layout>
		</Layout>
	);
};

const mapStateToProps = (state: GlobalState) => ({
	me: state.users.me,
});

const mapDispatchToProps = {
	userLoggedOut,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const MainLayout = connector(MainLayoutInner);
