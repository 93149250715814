import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

interface IAnonymousLayoutProps {

}

export const AnonymousLayout: React.FC<IAnonymousLayoutProps> = ({ children }) => (
  <Layout className="anonym-layout">
    <Content>
      { children }
    </Content>
  </Layout>
);
