import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import frFR from 'antd/es/locale/fr_FR';
import React, { useContext, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { handleAxiosAuth, handleAxiosErrors } from './api';
import './App.less';
import { AuthContext } from './context/AuthContext';
import { Routes } from './routes';
import { getMe } from './store/user/actions';
import { IAuthContext } from './utils/types/types';


const AppInner: React.FC<{} & ConnectedProps<typeof connector>> = ({
  getMe: getMeAction,
}) => {

  const { state, logout }: IAuthContext = useContext(AuthContext);

  useEffect(() => {
    handleAxiosErrors();
  }, []);

  useEffect(() => {
    if (state.token) getMeAction();
  }, [getMeAction, state.token]);

  if (state.token) handleAxiosAuth(state.token, logout);

  return (
    <ConfigProvider locale={frFR}>
      <Router>
        <Routes authorized={state.isLoggedIn} />
      </Router>
    </ConfigProvider>
  );
};

const mapDispatchToProps = {
  getMe,
};

const connector = connect(null, mapDispatchToProps);

export const App = connector(AppInner);
