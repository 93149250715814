import React, { createContext, useEffect, useState } from "react";
import { getTokenPackage, isValidToken } from "../../utils/token-utils";
import { IAuth, IToken } from '../../utils/types/types';

export const AuthContext: any = createContext({});

export const AuthContextProvider: React.FC<{}> = ({ children }) => {

  const storedToken: IToken | null = getTokenPackage();

  const tokenValid = !!storedToken && isValidToken(storedToken.expiresAt)

  const [state, setState] = useState<IAuth>({
    email: storedToken ? storedToken.email : null,
    token: storedToken && tokenValid ? storedToken.accessToken : null,
    isLoggedIn: storedToken ? true : false,
    isLoading: false,
    errors: null,
  });

  const makeLoading = () => setState({
    ...state,
    isLoading: true,
  });

  const login = (accessToken: string, expiresAt: number, email: string) => {
    const validToken = isValidToken(expiresAt);
    const tokenPack = {
      email,
      accessToken,
      expiresAt,
    };
    localStorage.setItem("googleAccessToken", JSON.stringify(tokenPack));
    setState({
      ...state,
      token: validToken ? accessToken : null,
      email,
      isLoggedIn: validToken,
      isLoading: false,
      errors: null,
    });
  };

  const loginError = (errors: string | null = null) => setState({
    ...state,
    isLoading: false,
    errors,
  });

  const logout = () => {
    localStorage.removeItem("googleAccessToken");
    setState({
      ...state,
      email: null,
      token: null,
      isLoggedIn: false,
    });
  };

  useEffect(() => {
    if (!tokenValid) {
      logout();
    }
  }, [tokenValid]);

  return (
    <AuthContext.Provider value={{ state, makeLoading, login, loginError, logout }}>
      { children}
    </AuthContext.Provider>
  );
};

