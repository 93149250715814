import { message } from "antd";
import { AxiosResponse } from 'axios';
import { put, takeLatest } from "redux-saga/effects";
import { addDepartmentRequest, getDepartmentByIdRequest, getDepartmentsRequest, getDepartmentTeamRequest, updateDepartmentRequest } from "../../api/requests";
import { IDepartment } from "../../utils/types/department";
import { IUser } from "../../utils/types/user";
import { addDepartmentError, addDepartmentSuccess, departmentGetByIdError, departmentGetByIdSuccess, getDepartmentList, getDepartmentListError, getDepartmentListSuccess, getDepartmentTeamList, getDepartmentTeamListError, getDepartmentTeamListSuccess, updateDepartmentError, updateDepartmentSuccess } from "./actions";
import { DEPARTMENT_ADD_NEW, DEPARTMENT_GET_BY_ID, DEPARTMENT_GET_LIST, DEPARTMENT_TEAM_GET_LIST, DEPARTMENT_UPDATE, IDepartmentAddAction, IDepartmentGetByIdAtion, IGetDepartmentTeamListAction, IUpdateDepartmentAction } from "./types";

/**
 * Add New Department
 */
function* addDepartment({ payload: department }: IDepartmentAddAction) {
  try {
    yield addDepartmentRequest(department);
    yield put(addDepartmentSuccess());
    message.success(`Le département ${department.title} a été ajouté avec succès`);
  } catch (error) {
    yield put(addDepartmentError(error));
  }
};

export function* watchAddDepartment() {
  yield takeLatest(DEPARTMENT_ADD_NEW, addDepartment);
};

/**
 * List Departments
 */
function* getListDepartments() {
  try {
    const res: AxiosResponse<IDepartment[]> = yield getDepartmentsRequest();
    yield put(getDepartmentListSuccess(res.data));
  } catch (error) {
    yield put(getDepartmentListError(error));
  }
};

export function* watchGetListDepartments() {
  yield takeLatest(DEPARTMENT_GET_LIST, getListDepartments);
};

/**
 * List Users Department
 */
function* getUsersListDepartment({ payload: id }: IGetDepartmentTeamListAction) {
  try {
    const res: AxiosResponse<IUser[]> = yield getDepartmentTeamRequest(id);
    yield put(getDepartmentTeamListSuccess(res.data));
  } catch (error) {
    yield put(getDepartmentTeamListError(error));
  }
};

export function* watchGetUsersListDepartment() {
  yield takeLatest(DEPARTMENT_TEAM_GET_LIST, getUsersListDepartment);
};

/**
 * Update Department
 */
function* updateDepartment({ payload: department }: IUpdateDepartmentAction) {
  try {
    yield updateDepartmentRequest(department);
    yield put(updateDepartmentSuccess());
    yield put(getDepartmentList());
    yield put(getDepartmentTeamList(department.id));
    message.destroy();
    message.success(`Le département ${department.title} a été mise à jour avec succès`);
  } catch (error) {
    yield put(updateDepartmentError(error));
  }
};

export function* watchUpdateDepartment() {
  yield takeLatest(DEPARTMENT_UPDATE, updateDepartment);
};

/**
 * Get Department by ID
 */
function* getDepartmentById({ payload: id }: IDepartmentGetByIdAtion) {
  try {
    const res: AxiosResponse<IDepartment> = yield getDepartmentByIdRequest(id);
    yield put(departmentGetByIdSuccess(res.data));
  } catch (error) {
    yield put(departmentGetByIdError(error));
  };
};

export function* watchGetDepartmentById() {
  yield takeLatest(DEPARTMENT_GET_BY_ID, getDepartmentById);
};
