import { EditOutlined, EyeOutlined, MoreOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Row, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { EmptyContent } from "../../../../../components/EmptyContent";
import { PermissionsHandler } from "../../../../../components/PermissionsHandler";
import { IMe, IUser } from "../../../../../utils/types/user";
import "./list-users.less";


const { Meta } = Card;

interface IListUsersProps {
	users: IUser[];
	me: IMe;
	onSelectUser: (user: any) => void;
}

export const ListUsers: React.FC<IListUsersProps> = ({
	users, me, onSelectUser,
}) => {

	if (!users.length) return <EmptyContent description="Aucun utilisateur" />;
	return (
		<Row gutter={[16, 16]}>
			{users.map(user => (
				<Col key={user.email} xl={6} md={8} sm={12} xs={24}>
					{
						me.data && <Card
							className='user-card'
							title={<Avatar size={100} icon={!user.avatar && <UserOutlined />} src={user.avatar && `${process.env.REACT_APP_BASE_URL}${user.avatar.url}`} />}
							bordered={false}
							actions={[
								(
									<Tooltip title="Profil">
										<Link to={`/users/${user.id}`}>
											<EyeOutlined key="view" />
										</Link>
									</Tooltip>
								),
								(
									<PermissionsHandler resourceName="User" blocPermission="CAN_PUT" connectedUserId={me.data.id} ownerId={user.id} userPermissions={me.data.permissions}>
										<Tooltip title="Modifier">
											<Link to={`/users/update/${user.id}`}>
												<EditOutlined key="edit" />
											</Link>
										</Tooltip>
									</PermissionsHandler>
								),
								<Tooltip title="Aperçu">
									<MoreOutlined onClick={() => onSelectUser(user)} key="more" />
								</Tooltip>,
							]}
						>
							<Meta
								title={`${user.firstName} ${user.lastName}`}
								description={user.role ? user.role?.title : '---'}
							/>
						</Card>
					}
				</Col>
			))}
		</Row>
	);
};
