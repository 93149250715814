import React from "react";
import { Helmet } from "react-helmet";

interface IPageTitleProps {
  title: string;
}

export const PageTitle: React.FC<IPageTitleProps> = ({title}) => {
  return (
    <Helmet>
      <title>Symdrik RH | {title}</title>
    </Helmet>
  );
}
