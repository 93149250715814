import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { ICreateDepartment } from "../../../../../utils/types/department";
import { IUser } from "../../../../../utils/types/user";

interface IAddFormProps {
  users: IUser[];
  onFormSubmitted: (department: ICreateDepartment) => void;
}

const { Item } = Form;
const { TextArea } = Input;
const { Option } = Select;

export const DepartmentForm: React.FC<IAddFormProps> = ({
  users,
  onFormSubmitted,
}) => {

  const handleFormSubmit = (values: any) => {
    if (values) onFormSubmitted(values);
  };

  return (
    <Form
      onFinish={handleFormSubmit}
      layout="vertical"
    >
      <Row gutter={16}>
        <Col md={12} xs={24}>

          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Item
                name="title"
                label="Titre"
                rules={[
                  { required: true, message: 'Le titre est obligatoire.' }
                ]}
              >
                <Input type="text" placeholder="Titre" />
              </Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Item name="description" label="Description">
                <TextArea placeholder="Description" />
              </Item>
            </Col>
          </Row>

        </Col>
        <Col md={12} xs={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Item name="responsible" label="Choisir un chef de pôle">
                <Select placeholder="Choisir un chef de pôle pour ce département">
                  {
                    users.map(user => <Option key={user.id} value={`/users/${user.id}`}>{user.firstName} {user.lastName}</Option>)
                  }
                </Select>
              </Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col>
          <Button htmlType="submit" type="primary">Ajouter</Button>
        </Col>
      </Row>

    </Form>
  );
};
