import { Card, Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import './custom-tabs.less';

const { Text } = Typography;

interface ICustomTabsProps {
  isResponsible: boolean;
  onTabChange: (seletedTab: 'me' | 'team') => void;
}

export const CustomTabs: React.FC<ICustomTabsProps> = ({
  isResponsible,
  onTabChange,
}) => {

  const [currentTab, setCurrentTab] = useState<'me' | 'team'>('me');
  const [isMe, setMe] = useState(false);

  useEffect(() => {
    setMe(currentTab === 'me');
    onTabChange(currentTab);
  }, [currentTab]);

  const handleCardClick = (tabName: 'me' | 'team') => setCurrentTab(tabName);

  return (
    <Row gutter={16} className="custom-tabs">
      <Col xs={isResponsible ? 12 : 24}>
        <Card bordered={false} onClick={() => handleCardClick('me')}>
          <Text className={isMe ? 'current-c-tab' : ''} strong={currentTab === 'me'}>Mes congés</Text>
        </Card>
      </Col>
      {
        isResponsible && (
          <Col xs={12}>
            <Card bordered={false} onClick={() => handleCardClick('team')}>
              <Text className={!isMe ? 'current-c-tab' : ''} strong={currentTab === 'team'}>Mon équipe</Text>
            </Card>
          </Col>
        )
      }

    </Row>
  );
};
