import { Divider, Menu, Spin } from "antd";
import React, { ReactText, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../../../components/Logo";
import { routes } from "../../../../routes";
import { hasPermission } from "../../../../utils/permissions-utils";
import { ILayout, IRoute } from "../../../../utils/types/types";
import { IMe, IUserPermissions } from "../../../../utils/types/user";
import { ConnectedUserMenu } from "../ConnectedUserMenu";
import './navbar.less';

const { SubMenu } = Menu;

interface INavbarProps {
	me: IMe;
	selectedKey: string;
};

const generateSubMenus = (subRoutes: IRoute[], userPermissions: IUserPermissions) => {
	if (!subRoutes) return [];
	return subRoutes.map((r) => {
		if (!r.hasSiderLink) return null;
		if (!r.routes) {
			if (!r.path) return null;
			if (r.resources && !hasPermission(userPermissions, r.resources)) return null;
			return (
				<Menu.Item key={r.path}>
					<Link to={r.path}>
						{!!r.icon && r.icon}
						<span>{r.title}</span>
					</Link>
				</Menu.Item>
			);
		}
		return (
			<SubMenu
				key={r.name}
				title={
					<span>
						{r.icon}
						<span>{r.title}</span>
					</span>
				}>
				{generateSubMenus(r.routes, userPermissions)}
			</SubMenu>
		);
	});
};

const generateMenu = (layouts: ILayout[], userPermissions: IUserPermissions) => {
	return layouts.map((layout) => generateSubMenus(layout.routes, userPermissions));
};

export const Navbar: React.FC<INavbarProps> = ({
	me,
	selectedKey,
}) => {
	const [openKeys, setOpenKeys] = useState<string[]>([]);
	const [currentSelectedKey, setCurrentSelectedKey] = useState<string[]>([]);

	const { data: connectedUser, isLoading } = me;

	useEffect(() => {
		setCurrentSelectedKey([selectedKey]);
		setOpenKeys([selectedKey]);
	}, [selectedKey]);

	useEffect(() => {
	}, [selectedKey]);

	useEffect(() => {
	}, [currentSelectedKey]);

	const handleOpenSubMenu = (keys: ReactText[]) => {
		const newKeys = keys.map(String);
		if (!newKeys.length) setOpenKeys([]);
		const latestOpenKey = newKeys.find((key) => openKeys?.indexOf(key) === -1);
		if (latestOpenKey) setOpenKeys([latestOpenKey]);
		else setOpenKeys([]);
	};

	const handleSelectItem = (SelectEventHandler: any) => {
		setCurrentSelectedKey(SelectEventHandler.keyPath);
	};

	return (
		<>
			<Logo location='sider' />
			{/* Connected user */}
			<div className={`sider-user-coords-container ${isLoading ? 'bloc-loading-mode' : ''}`}>
				<Divider />
				{
					connectedUser ?
						(
							<ConnectedUserMenu user={connectedUser} loading={isLoading} />
						) : <Spin tip="Chargement..." />
				}
				<Divider />
			</div>
			{/* Connected user */}
			<Menu
				mode='inline'
				openKeys={openKeys}
				defaultSelectedKeys={['/']}
				selectedKeys={currentSelectedKey}
				onOpenChange={handleOpenSubMenu}
				onSelect={handleSelectItem}
			>
				{!!me.data && generateMenu(routes, me.data.permissions)}
			</Menu>
		</>
	);
};
