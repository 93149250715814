import { IToken } from "./types/types";

export const getTokenPackage = (): IToken | null => {
  const storedToken = localStorage.getItem("googleAccessToken");
  return storedToken ? JSON.parse(storedToken) : null;
};

export const isValidToken = (exp: number | null) => {
  if (exp) return exp >= Math.floor(Date.now());
  return false;
};
