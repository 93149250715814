import { Card, Col, Row, Typography } from 'antd';
import React from 'react';
import { IDepartment } from '../../../../../utils/types/department';
import './calendar-filter.less';


const { Title } = Typography;

interface ICalendarFilterProps {
  departments: IDepartment[];
  onDepartmentChange: (id: string) => void;
};

export const CalendarFilter: React.FC<ICalendarFilterProps> = ({
  departments,
  onDepartmentChange,
}) => {

  return (
    <div className="calendar-filters">
      <Title level={5}>Filtrer par département</Title>
      <Row gutter={[16, 16]}>
        {
          departments.map(({ title, id }) => (
            <Col key={id} lg={4} sm={8} xs={24}>
              <Card
                className="filter-card"
                bordered={false}
                onClick={() => onDepartmentChange(id)}
              >
                {title.replace('Département', '')}
              </Card>
            </Col>
          ))
        }
      </Row>
    </div>
  );
};
