import { useLayoutEffect, useState } from 'react';
import { deviceBreakpoints } from '../utils/vars';

export const useWindowResize = () => {

  const [isMobile, setMobile] = useState(false);

  const [isXs, setXs] = useState(false);
  const [isSm, setSm] = useState(false);
  const [isMd, setMd] = useState(false);
  const [isLg, setLg] = useState(false);
  const [isXl, setXl] = useState(false);
  const [isXxl, setXxl] = useState(false);

  useLayoutEffect(() => {
    const updateSize = () => {
      setMobile(window.innerWidth < deviceBreakpoints.lg);

      setXs(window.innerWidth < deviceBreakpoints.sm);
      setSm(window.innerWidth < deviceBreakpoints.md);
      setMd(window.innerWidth < deviceBreakpoints.lg);
      setLg(window.innerWidth < deviceBreakpoints.xl);
      setXl(window.innerWidth < deviceBreakpoints.xxl);
      setXxl(window.innerWidth >= deviceBreakpoints.xxl);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return { isMobile, isXs, isSm, isMd, isLg, isXl, isXxl };
};
