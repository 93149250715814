import { message } from "antd";
import { AxiosResponse } from "axios";
import { put, takeLatest } from "redux-saga/effects";
import { addLeaveRequest, cancelLeaveRequest, getLeaveByIdRequest, getLeavesRequest, getLeavesTypesRequest, getMyLeaveRequest, updateLeaveDecisionRequest, updateLeaveRequest } from "../../api/requests";
import { ILeave, ILeaveType } from "../../utils/types/leave";
import { cancelLeaveError, cancelLeaveSuccess, getMyLeavesError, getMyLeavesSuccess, leaveAddRequestError, leaveAddRequestSuccess, leaveDecisionUpdateError, leaveDecisionUpdateSuccess, leaveGetById as leaveGetByIdAction, leaveGetByIdError, leaveGetByIdSuccess, leaveGetList, leaveGetListError, leaveGetListSuccess, leaveTypesGetListError, leaveTypesGetListSuccess, leaveUpdateError, leaveUpdateSuccess } from "./actions";
import { GET_MY_LEAVES, IGetMyLeavesAction, ILeaveAddRequestAction, ILeaveCancelAction, ILeaveDecisionUpdateAction, ILeaveGetByIdAction, ILeaveGetListAction, ILeaveUpdateAction, LEAVES_TYPES_GET_LIST, LEAVE_ADD_REQUEST, LEAVE_CANCEL, LEAVE_DECISION_UPDATE, LEAVE_GET_BY_ID, LEAVE_GET_LIST, LEAVE_UPDATE } from "./types";


/**
 * Add leave request
 */
function* addLeave({ payload: leave }: ILeaveAddRequestAction) {
  try {
    yield addLeaveRequest(leave);
    yield put(leaveAddRequestSuccess());
    message.success('Votre demande a été ajoutée avec succès', 5);
  } catch (error) {
    yield put(leaveAddRequestError(error));
  };
};

export function* watchAddLeave() {
  yield takeLatest(LEAVE_ADD_REQUEST, addLeave);
};

/**
 * Get List leaves types
 */
function* getLeavesTypes() {
  try {
    const res: AxiosResponse<ILeaveType[]> = yield getLeavesTypesRequest();
    yield put(leaveTypesGetListSuccess(res.data));
  } catch (error) {
    yield put(leaveTypesGetListError(error));
  };
};

export function* WatchGetLeavesTypes() {
  yield takeLatest(LEAVES_TYPES_GET_LIST, getLeavesTypes);
};

/*
 * Get Leaves List
 */
function* getLeaves({ payload: filters }: ILeaveGetListAction) {
  try {
    const res: AxiosResponse<ILeave[]> = yield getLeavesRequest(filters);
    yield put(leaveGetListSuccess(res.data));
  } catch (error) {
    yield put(leaveGetListError(error));
  };
};

export function* watchGetLeaves() {
  yield takeLatest(LEAVE_GET_LIST, getLeaves);
};

/*
 * Get My Leaves
 */
function* getMyLeaves({ payload: terms }: IGetMyLeavesAction) {
  try {
    const res: AxiosResponse<ILeave[]> = yield getMyLeaveRequest(terms);
    yield put(getMyLeavesSuccess(res.data));
  } catch (error) {
    yield put(getMyLeavesError(error));
  };
};

export function* watchGetMyLeaves() {
  yield takeLatest(GET_MY_LEAVES, getMyLeaves);
};

/**
 * Get leave By id
 */
function* getLeaveById({ payload: id }: ILeaveGetByIdAction) {
  try {
    const res: AxiosResponse<ILeave> = yield getLeaveByIdRequest(id);
    yield put(leaveGetByIdSuccess(res.data));
  } catch (error) {
    yield put(leaveGetByIdError(error));
  };
};

export function* watchGetLeaveById() {
  yield takeLatest(LEAVE_GET_BY_ID, getLeaveById);
};

/**
 * Update Leave
 */
function* updateLeave({ payload: leave }: ILeaveUpdateAction) {
  try {
    yield updateLeaveRequest(leave);
    yield put(leaveUpdateSuccess());
    message.success('Votre demande de congé a été mise à jour avec succès.', 5);
  } catch (error) {
    yield put(leaveUpdateError(error));
  };
};

export function* watchUpdateLeave() {
  yield takeLatest(LEAVE_UPDATE, updateLeave);
};

/**
 * Cancel leave
 */
function* cancelLeave({ payload: id }: ILeaveCancelAction) {
  try {
    yield cancelLeaveRequest(id);
    yield put(cancelLeaveSuccess());
    yield put(leaveGetByIdAction(id));
    message.success('Votre demande a été annulée avec succès.', 5);
  } catch (error) {
    yield put(cancelLeaveError(error));
  };
};

export function* watchCancelLeave() {
  yield takeLatest(LEAVE_CANCEL, cancelLeave);
};

/**
 * Update Leave Decision
 */
function* updateDecisionLeave({ payload: leaveDecision }: ILeaveDecisionUpdateAction) {
  try {
    yield updateLeaveDecisionRequest(leaveDecision);
    yield put(leaveDecisionUpdateSuccess());
    yield put(leaveGetList());
    message.success('Votre demande de congé a été mise à jour avec succès.', 5);
  } catch (error) {
    yield put(leaveDecisionUpdateError(error));
  };
};

export function* watchUpdateLeaveDecision() {
  yield takeLatest(LEAVE_DECISION_UPDATE, updateDecisionLeave);
};
