import { AxiosError } from 'axios';
import { IListLeavesFilter } from '../store/leave/types';
import { IErrors } from './types/types';


interface IViolation {
  propertyPath: string;
  message: string;
}

export const parseAxiosError = (error: AxiosError): IErrors => {
  // if not response
  if (!error.response) return { general: [error.message] };

  const data = error.response.data;
  if (data.violations) return violationsErrorTransformer(data.violations);
  if (data.detail) return { general: [data.detail.split(':')[-1]] };
  if (Array.isArray(data)) return { general: data };
  return { general: [error.response.data.message] };
};

const violationsErrorTransformer = (violations: IViolation[]): IErrors => {
  const errors: IErrors = {};
  violations.forEach(({ propertyPath, message }) => {
    errors[propertyPath] ? errors[propertyPath].push(message) : errors[propertyPath] = [message];
  });
  return errors;
};

export const generateFilterUrl = (basePath: string, filters?: IListLeavesFilter) => {
  if (!filters) return `${basePath}?page=1`;
  return `${basePath}?${filters.filterBy}=${filters.filterValue}&page=1`;
};
