import { Reducer } from 'redux';
import { DepartmentActionTypes, DEPARTMENT_ADD_NEW, DEPARTMENT_ADD_NEW_ERROR, DEPARTMENT_ADD_NEW_SUCCESS, DEPARTMENT_GET_BY_ID, DEPARTMENT_GET_BY_ID_CLEAR, DEPARTMENT_GET_BY_ID_ERROR, DEPARTMENT_GET_BY_ID_SUCCESS, DEPARTMENT_GET_LIST, DEPARTMENT_GET_LIST_ERROR, DEPARTMENT_GET_LIST_SUCCESS, DEPARTMENT_TEAM_GET_LIST, DEPARTMENT_TEAM_GET_LIST_ERROR, DEPARTMENT_TEAM_GET_LIST_SUCCESS, DEPARTMENT_UPDATE, DEPARTMENT_UPDATE_ERROR, DEPARTMENT_UPDATE_SUCCESS, IDepartmentState } from "./types";

export const departmentInitialState: IDepartmentState = {
  AddDepartment: {
    isCreated: false,
    isLoading: false,
    errors: null,
  },
  department: {
    data: null,
    isLoading: false,
    errors: null,
  },
  departmentList: {
    data: [],
    isLoading: false,
    errors: null,
  },
  departmentTeam: {
    data: [],
    isLoading: false,
    errors: null,
  },
  UpdateDepartment: {
    data: null,
    isUpdated: false,
    isLoading: false,
    errors: null,
  },
};

export const departmentReducer: Reducer<typeof departmentInitialState, DepartmentActionTypes> = (state = departmentInitialState, action) => {
  switch (action.type) {

    case DEPARTMENT_ADD_NEW:
      return {
        ...state,
        AddDepartment: {
          ...state.AddDepartment,
          isLoading: true,
        },
      };

    case DEPARTMENT_ADD_NEW_SUCCESS:
      return {
        ...state,
        AddDepartment: {
          ...state.AddDepartment,
          isLoading: false,
        },
      };

    case DEPARTMENT_ADD_NEW_ERROR:
      return {
        ...state,
        AddDepartment: {
          ...state.AddDepartment,
          isLoading: false,
          errors: action.payload,
        },
      };

    case DEPARTMENT_GET_LIST:
      return {
        ...state,
        departmentList: {
          ...state.departmentList,
          isLoading: true,
          errors: null,
        },
      };

    case DEPARTMENT_GET_LIST_SUCCESS:
      return {
        ...state,
        departmentList: {
          ...state.departmentList,
          data: action.payload,
          isLoading: false,
        },
      };

    case DEPARTMENT_GET_LIST_ERROR:
      return {
        ...state,
        departmentList: {
          ...state.departmentList,
          isLoading: false,
          errors: action.payload,
        },
      };

    case DEPARTMENT_TEAM_GET_LIST:
      return {
        ...state,
        departmentTeam: {
          ...state.departmentTeam,
          isLoading: true,
          errors: null,
        },
      };

    case DEPARTMENT_TEAM_GET_LIST_SUCCESS:
      return {
        ...state,
        departmentTeam: {
          ...state.departmentTeam,
          data: action.payload,
          isLoading: false,
        },
      };

    case DEPARTMENT_TEAM_GET_LIST_ERROR:
      return {
        ...state,
        departmentTeam: {
          ...state.departmentTeam,
          isLoading: false,
          errors: action.payload,
        },
      };

    case DEPARTMENT_UPDATE:
      return {
        ...state,
        UpdateDepartment: {
          ...state.UpdateDepartment,
          isLoading: true,
        },
      };

    case DEPARTMENT_UPDATE_SUCCESS:
      return {
        ...state,
        UpdateDepartment: {
          ...state.UpdateDepartment,
          isLoading: false,
        },
      };

    case DEPARTMENT_UPDATE_ERROR:
      return {
        ...state,
        UpdateDepartment: {
          ...state.UpdateDepartment,
          isLoading: false,
          errors: action.payload,
        },
      };

    // Get By ID
    case DEPARTMENT_GET_BY_ID:
      return {
        ...state,
        department: {
          ...state.department,
          isLoading: true,
          errors: null,
        },
      };

    case DEPARTMENT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        department: {
          ...state.department,
          isLoading: false,
          data: action.payload,
        },
      };

    case DEPARTMENT_GET_BY_ID_ERROR:
      return {
        ...state,
        department: {
          ...state.department,
          isLoading: false,
          errors: action.payload,
        },
      };

    case DEPARTMENT_GET_BY_ID_CLEAR:
      return {
        ...state,
        department: {
          ...state.department,
          data: null,
        },
      };

    default:
      return state;
  };
};
