import { CalendarOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Form, Input, Row, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IUpdateLeaveDecision } from '../../../../../store/leave/types';
import { ILeave, ILeaveDecision } from '../../../../../utils/types/leave';

interface IModalLeaveDecisionProps {
  leave: ILeave;
  role?: String;
  leaveDecision: IUpdateLeaveDecision;
  isModalVisible: boolean;
  onUpdateLeaveDecisionAction: (leaveDecision: ILeaveDecision) => void;
  onCloseModal: (state: boolean) => void;
}

export const ModalLeaveDecision: React.FC<IModalLeaveDecisionProps> = ({
  leave,
  role,
  leaveDecision,
  isModalVisible,
  onUpdateLeaveDecisionAction,
  onCloseModal,
}) => {

  const { Item } = Form;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { isUpdated } = leaveDecision;

  const [leaveDecisionId, setLeaveDecisionId] = useState<string | undefined>();
  const [decision, setDecision] = useState<boolean | null>(null);

  useEffect(() => {
    if (role && role === "ROLE_MANAGER" && leave) setLeaveDecisionId(leave.directorDecision?.id);

    if (role && role === "ROLE_RP" && leave) setLeaveDecisionId(leave.responsibleDecision?.id);
  }, [role, leave]);

  useEffect(() => {
    if (isUpdated) form.resetFields();
  }, [isUpdated]);

  const handleFormSubmited = (values: any) => {
    onUpdateLeaveDecisionAction({ ...values, id: leaveDecisionId, decision });
  };

  const handleCloseModal = () => onCloseModal(false);

  return (
    <Modal
      title={`Demande de ${leave.type ? leave.type.name : ''} déposé le ${moment(leave.createdAt).format('DD/MM/YYYY')} par ${leave.user.firstName} ${leave.user.lastName}`}
      visible={isModalVisible}
      onCancel={handleCloseModal}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmited}
      >
        <Row gutter={[16, 16]}>
          <Col md={12} xs={24}>
            <CalendarOutlined /> Date de début: <strong>{moment(leave.startingDate).format('DD/MM/YYYY')}</strong>
          </Col>
          <Col md={12} xs={24}>
            <CalendarOutlined /> Date de fin: <strong>{moment(leave.endingDate).format('DD/MM/YYYY')}</strong>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Divider orientation="left">Pour info:</Divider>
            <Alert
              message={
                <Row gutter={[8, 8]}>
                  <Col md={12} xs={24}>
                    <strong>Solde actuel: 7.5</strong>
                  </Col>
                  <Col md={12} xs={24}>
                    <strong>Solde annuel: 12</strong>
                  </Col>
                </Row>
              }
              type="info"
            />
            <Row justify="end">
              <Col>
                <Link to="/">Accéder à l'historique des congés de {leave.user.firstName} {leave.user.lastName}</Link>
              </Col>
            </Row>
            <Divider />

          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Item name="explanation" label="Motif décision">
              <TextArea placeholder="Motif décision" />
            </Item>
          </Col>
        </Row>

        <Row justify="end" gutter={[16, 16]}>
          <Col xs={12}>
            <Space direction="horizontal">
              <Button key="no" icon={<CloseOutlined />} htmlType="submit" type="primary" onClick={() => setDecision(false)} danger>Refuser</Button>
              <Button key="yes" icon={<CheckOutlined />} htmlType="submit" type="primary" onClick={() => setDecision(true)}>Accepter</Button>
            </Space>
          </Col>
        </Row>
      </Form>

    </Modal>
  )
}
