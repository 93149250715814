import { Reducer } from 'redux';
import { GET_MY_LEAVES, GET_MY_LEAVES_ERROR, GET_MY_LEAVES_SUCCESS, ILeaveState, leavesActionType, LEAVES_TYPES_GET_LIST, LEAVES_TYPES_GET_LIST_ERROR, LEAVES_TYPES_GET_LIST_SUCCESS, LEAVE_ADD_REQUEST, LEAVE_ADD_REQUEST_ERROR, LEAVE_ADD_REQUEST_RESET_CREATED, LEAVE_ADD_REQUEST_SUCCESS, LEAVE_CANCEL, LEAVE_CANCEL_ERROR, LEAVE_CANCEL_SUCCESS, LEAVE_DECISION_UPDATE, LEAVE_DECISION_UPDATE_ERROR, LEAVE_DECISION_UPDATE_RESET, LEAVE_DECISION_UPDATE_SUCCESS, LEAVE_GET_BY_ID, LEAVE_GET_BY_ID_CLEAR, LEAVE_GET_BY_ID_ERROR, LEAVE_GET_BY_ID_SUCCESS, LEAVE_GET_LIST, LEAVE_GET_LIST_ERROR, LEAVE_GET_LIST_SUCCESS, LEAVE_UPDATE, LEAVE_UPDATE_ERROR, LEAVE_UPDATE_SUCCESS } from './types';


export const leavesInitialState: ILeaveState = {
  myLeaves: {
    data: [],
    isLoading: false,
    errors: null,
  },
  list: {
    data: [],
    isLoading: false,
    errors: null,
  },
  leave: {
    data: null,
    isLoading: false,
    errors: null,
  },
  addLeave: {
    isLoading: false,
    isCreated: false,
    errors: null,
  },
  update: {
    isLoading: false,
    errors: null,
  },
  cancel: {
    isLoading: false,
    errors: null,
  },
  leaveTypes: {
    data: [],
    isLoading: false,
    errors: null,
  },
  leaveDecisionUpdate: {
    isLoading: false,
    isUpdated: false,
    errors: null,
  },
};

export const leavesReducer: Reducer<typeof leavesInitialState, leavesActionType> = (state = leavesInitialState, action) => {
  switch (action.type) {

    // Request a leave
    case LEAVE_ADD_REQUEST:
      return {
        ...state,
        addLeave: {
          isLoading: true,
          isCreated: false,
          errors: null,
        },
      };

    case LEAVE_ADD_REQUEST_SUCCESS:
      return {
        ...state,
        addLeave: {
          ...state.addLeave,
          isLoading: false,
          isCreated: true,
        },
      };

    case LEAVE_ADD_REQUEST_ERROR:
      return {
        ...state,
        addLeave: {
          ...state.addLeave,
          isLoading: false,
          errors: action.payload,
        },
      };

    case LEAVE_ADD_REQUEST_RESET_CREATED:
      return {
        ...state,
        addLeave: {
          ...state.addLeave,
          isCreated: false,
        },
      };

    // Get leave types
    case LEAVES_TYPES_GET_LIST:
      return {
        ...state,
        leaveTypes: {
          ...state.leaveTypes,
          isLoading: true,
          errors: null,
        },
      };

    case LEAVES_TYPES_GET_LIST_SUCCESS:
      return {
        ...state,
        leaveTypes: {
          ...state.leaveTypes,
          data: action.payload,
          isLoading: false,
        },
      };

    case LEAVES_TYPES_GET_LIST_ERROR:
      return {
        ...state,
        leaveTypes: {
          ...state.leaveTypes,
          isLoading: false,
          errors: action.payload,
        },
      };

    // Get my leaves
    case GET_MY_LEAVES:
      return {
        ...state,
        myLeaves: {
          ...state.myLeaves,
          isLoading: true,
          errors: null,
        },
      };

    case GET_MY_LEAVES_SUCCESS:
      return {
        ...state,
        myLeaves: {
          ...state.myLeaves,
          isLoading: false,
          data: action.payload,
        },
      };

    case GET_MY_LEAVES_ERROR:
      return {
        ...state,
        myLeaves: {
          ...state.myLeaves,
          isLoading: false,
          errors: action.payload,
        },
      };

    // Get leaves list
    case LEAVE_GET_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          errors: null,
        },
      };

    case LEAVE_GET_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: action.payload,
        },
      };

    case LEAVE_GET_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          errors: action.payload,
        },
      };

    // Get Leave by ID
    case LEAVE_GET_BY_ID:
      return {
        ...state,
        leave: {
          ...state.leave,
          isLoading: true,
          errors: null,
        },
      };

    case LEAVE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        leave: {
          ...state.leave,
          isLoading: false,
          data: action.payload,
        },
      };

    case LEAVE_GET_BY_ID_ERROR:
      return {
        ...state,
        leave: {
          ...state.leave,
          isLoading: false,
          errors: action.payload,
        },
      };

    case LEAVE_GET_BY_ID_CLEAR:
      return {
        ...state,
        leave: {
          ...state.leave,
          data: null,
        },
      };

    // Update Leave
    case LEAVE_UPDATE:
      return {
        ...state,
        update: {
          isLoading: true,
          errors: null,
        },
      };

    case LEAVE_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
        },
      };

    case LEAVE_UPDATE_ERROR:
      return {
        ...state,
        update: {
          isLoading: false,
          errors: action.payload,
        },
      };

    // Cancel leave
    case LEAVE_CANCEL:
      return {
        ...state,
        cancel: {
          isLoading: true,
          errors: null,
        },
      };

    case LEAVE_CANCEL_SUCCESS:
      return {
        ...state,
        cancel: {
          ...state.cancel,
          isLoading: false,
        },
      };

    case LEAVE_CANCEL_ERROR:
      return {
        ...state,
        cancel: {
          isLoading: false,
          errors: action.payload,
        },
      };

    // Update Leave Decision
    case LEAVE_DECISION_UPDATE:
      return {
        ...state,
        leaveDecisionUpdate: {
          ...state.leaveDecisionUpdate,
          isLoading: true,
          errors: null,
        },
      };

    case LEAVE_DECISION_UPDATE_SUCCESS:
      return {
        ...state,
        leaveDecisionUpdate: {
          ...state.leaveDecisionUpdate,
          isLoading: false,
          isUpdated: true,
        },
      };

    case LEAVE_DECISION_UPDATE_RESET:
      return {
        ...state,
        leaveDecisionUpdate: {
          ...state.leaveDecisionUpdate,
          isUpdated: false,
        },
      };

    case LEAVE_DECISION_UPDATE_ERROR:
      return {
        ...state,
        leaveDecisionUpdate: {
          ...state.leaveDecisionUpdate,
          isLoading: false,
          errors: action.payload,
        },
      };

    default:
      return state;
  }
};
