import { BellOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Popover, Row, Tooltip, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './notifications.less';

const { Text, Title } = Typography;

interface INotificationsProps {

}

export const Notifications: React.FC<INotificationsProps> = () => {

  const [isPopoverVisible, setPopoverVisibility] = useState(false);

  return (
    <Popover
      title={<Title level={4}>Notifiations</Title>}
      content={(
        <>
          <Link to="/leaves" onClick={() => setPopoverVisibility(false)}>
            <Row justify="space-between">
              <Col span={5}>
                <Avatar size={50} icon={<UserOutlined />} />
              </Col>
              <Col span={19}>
                <Text>Mouhamed selmi a déposé une demande de <Text type="success">congé</Text>.</Text>
              </Col>
            </Row>
          </Link>
          <Link to="/leaves">
            <Row justify="space-between">
              <Col span={5}>
                <Avatar size={50} icon={<UserOutlined />} />
              </Col>
              <Col span={19}>
                <Text>Amine Ched a déposé une demande de <Text type="warning">sertie exceptionnelle</Text>.</Text>
              </Col>
            </Row>
          </Link>
          <Link to="/leaves">
            <Row justify="space-between">
              <Col span={5}>
                <Avatar size={50} icon={<UserOutlined />} />
              </Col>
              <Col span={19}>
                <Text>Ahmed Mehrez a déposé une demande de congé.</Text>
              </Col>
            </Row>
          </Link>
          <Link to="/leaves">
            <Row justify="space-between">
              <Col span={5}>
                <Avatar size={50} icon={<UserOutlined />} />
              </Col>
              <Col span={19}>
                <Text>Ahmed Mehrez a déposé une demande de congé.</Text>
              </Col>
            </Row>
          </Link>
          <Link to="/leaves">
            <Row justify="space-between">
              <Col span={5}>
                <Avatar size={50} icon={<UserOutlined />} />
              </Col>
              <Col span={19}>
                <Text>Ahmed Mehrez a déposé une demande de congé.</Text>
              </Col>
            </Row>
          </Link>
          <Link to="/leaves">
            <Row justify="space-between">
              <Col span={5}>
                <Avatar size={50} icon={<UserOutlined />} />
              </Col>
              <Col span={19}>
                <Text>Ahmed Mehrez a déposé une demande de congé.</Text>
              </Col>
            </Row>
          </Link>
          <Link to="/leaves">
            <Row justify="space-between">
              <Col span={5}>
                <Avatar size={50} icon={<UserOutlined />} />
              </Col>
              <Col span={19}>
                <Text>Ahmed Mehrez a déposé une demande de congé.</Text>
              </Col>
            </Row>
          </Link>
          <Link to="/leaves">
            <Row justify="space-between">
              <Col span={5}>
                <Avatar size={50} icon={<UserOutlined />} />
              </Col>
              <Col span={19}>
                <Text>Ahmed Mehrez a déposé une demande de congé.</Text>
              </Col>
            </Row>
          </Link>
          <Link to="/leaves">
            <Row justify="space-between">
              <Col span={5}>
                <Avatar size={50} icon={<UserOutlined />} />
              </Col>
              <Col span={19}>
                <Text>Ahmed Mehrez a déposé une demande de congé.</Text>
              </Col>
            </Row>
          </Link>
          <Link to="/leaves">
            <Row justify="space-between">
              <Col span={5}>
                <Avatar size={50} icon={<UserOutlined />} />
              </Col>
              <Col span={19}>
                <Text>Ahmed Mehrez a déposé une demande de congé.</Text>
              </Col>
            </Row>
          </Link>
        </>
      )}
      placement="bottomRight"
      trigger="click"
      visible={isPopoverVisible}
      onVisibleChange={setPopoverVisibility}
      overlayClassName="header-popover notifications"
    >
      <Tooltip title="Notifications">
        <Badge
          count={5}
          offset={[-5, 5]}
        >
          <Button icon={<BellOutlined />} />
        </Badge>
      </Tooltip>
    </Popover>
  );
};
