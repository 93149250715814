import { Badge, Calendar, Layout, Popover, Space, Tag, Typography } from 'antd';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useState } from 'react';
import { ILeave } from '../../../../../utils/types/leave';
import { exceptionalLeaveSlug } from '../../../../../utils/vars';
import { CalendarDrawer } from '../CalendarDrawer';

const { Title, Text } = Typography;
moment.locale('fr');

interface IAppCalendarProps {
  leaves: ILeave[];
};

export const AppCalendar: React.FC<IAppCalendarProps> = ({
  leaves,
}) => {

  const momentFormat = 'DD-MM-YYYY';

  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
  const [calendarMode, setCalendarMode] = useState<CalendarMode>('month');
  const [isDrawerVisible, setDrawerVisibility] = useState(false);

  const handleClosingDrawer = () => {
    setDrawerVisibility(false);
  };

  const handleCalendarSelect = (date: moment.Moment) => {
    setSelectedDate(date);
    setDrawerVisibility(true);
  };

  const handleCalendarMode = (date: moment.Moment, mode: CalendarMode) => setCalendarMode(mode);

  const renderWeekend = (date: moment.Moment) => (moment(date).format('dddd') === 'dimanche' || moment(date).format('dddd') === 'samedi') ?
    <Tag color="gold">Weekend</Tag>
    :
    undefined;

  const renderCell = (date: moment.Moment, cellLeaves: ILeave[]) => {
    return (
      <>
        <Space direction="vertical">
          {calendarMode === 'month' && renderWeekend(date)}
          {
            cellLeaves.map(cellLeave => {
              const type = cellLeave.type.slug === exceptionalLeaveSlug
                ? 'warning' : 'success';
              if (moment(date).format('dddd') === 'dimanche') return null;
              if (moment(date).isBetween(moment(cellLeave.startingDate), moment(cellLeave.endingDate).add(1, 'day')))
                return (
                  <Popover
                    key={cellLeave.id}
                    title={<Title level={5}>{cellLeave.user.firstName} {cellLeave.user.lastName}</Title>}
                    content={(
                      <Space direction="vertical">
                        <Text type={type}>{cellLeave.type.name}</Text>
                        <Text>De: {moment(cellLeave.startingDate).format(momentFormat)}</Text>
                        <Text>À: {moment(cellLeave.endingDate).format(momentFormat)}</Text>
                      </Space>
                    )}
                  >
                    <Badge
                      status={type}
                      text={`${cellLeave.user.firstName} ${cellLeave.user.lastName}`}
                    />
                  </Popover>
                )
            })
          }
        </Space>
      </>
    );
  };

  return (
    <Layout className="content-layout">
      <Calendar
        value={selectedDate}
        onSelect={handleCalendarSelect}
        dateCellRender={
          (date: moment.Moment) => renderCell(date, leaves)
        }
        monthCellRender={
          (date: moment.Moment) => renderCell(date, leaves)
        }
        onPanelChange={handleCalendarMode}
      />
      <CalendarDrawer
        selectedDate={selectedDate}
        mode={calendarMode}
        leaves={leaves}
        isVisible={isDrawerVisible}
        onDrawerClose={handleClosingDrawer}
      />
    </Layout>
  );
};
