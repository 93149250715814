import { Action } from "redux";
import { IErrors } from "../../utils/types/types";
import { IRole } from "../../utils/types/user";

export interface IRoleState {
  rolesList: IRole[];
  isLoading: boolean;
  errors: IErrors | null;
};

export const ROLES_GET_LIST = 'ROLES_GET_LIST';
export type ROLES_GET_LIST = typeof ROLES_GET_LIST;

export const ROLES_GET_LIST_SUCCESS = 'ROLES_GET_LIST_SUCCESS';
export type ROLES_GET_LIST_SUCCESS = typeof ROLES_GET_LIST_SUCCESS;

export const ROLES_GET_LIST_ERROR = 'ROLES_GET_LIST_ERROR';
export type ROLES_GET_LIST_ERROR = typeof ROLES_GET_LIST_ERROR;

export interface IGetRolesAction extends Action<ROLES_GET_LIST> { };

export interface IGetRolesSuccessAction extends Action<ROLES_GET_LIST_SUCCESS> {
  payload: IRole[];
};

export interface IGetRolesErrorAction extends Action<ROLES_GET_LIST_ERROR> {
  payload: IErrors;
};

export type RoleActionTypes =
  IGetRolesAction
  | IGetRolesSuccessAction
  | IGetRolesErrorAction;
