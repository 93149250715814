import { put, takeLatest } from "redux-saga/effects";
import { getRolesRequest } from "../../api/requests";
import { getRolesError, getRolesSuccess } from "./actions";
import { ROLES_GET_LIST } from "./types";


function* getRoles() {
  try {
    const res = yield getRolesRequest();
    yield put(getRolesSuccess(res.data));
  } catch (error) {
    yield put(getRolesError(error));
  }
};

export function* watchGetRoles() {
  yield takeLatest(ROLES_GET_LIST, getRoles);
};
