import { Reducer } from "redux";
import { GET_USER, GET_USER_ERROR, GET_USER_SUCCESS, IUsersState, TOGGLE_ENABLE_USER, TOGGLE_ENABLE_USER_ERROR, TOGGLE_ENABLE_USER_SUCCESS, UsersActionTypes, USERS_GET_LIST, USERS_GET_LIST_ERROR, USERS_GET_LIST_SUCCESS, USER_GET_ME, USER_GET_ME_ERROR, USER_GET_ME_SUCCESS, USER_UPDATE, USER_UPDATE_ERROR, USER_UPDATE_SUCCESS } from "./types";

export const usersInitialState: IUsersState = {
  usersList: {
    data: [],
    isLoading: false,
    errors: null,
  },
  me: {
    data: null,
    isLoading: false,
    errors: null,
  },
  user: {
    data: null,
    isLoading: false,
    errors: null,
  },
  update: {
    isLoading: false,
    errors: null,
  },
  toggleEnable: {
    isLoading: false,
    errors: null,
  }
}

export const usersReducer: Reducer<typeof usersInitialState, UsersActionTypes> = (state = usersInitialState, action) => {
  switch (action.type) {
    // Get users
    case USERS_GET_LIST:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          isLoading: true,
          errors: null,
        },
      };

    case USERS_GET_LIST_SUCCESS:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: action.payload,
          isLoading: false,
        },
      };

    case USERS_GET_LIST_ERROR:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          isLoading: false,
          errors: action.payload,
        },
      };

    // Get user
    case GET_USER:
      return {
        ...state,
        user: {
          ...state.user,
          data: null,
          isLoading: true,
          errors: null,
        },
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          data: action.payload,
        }
      };

    case GET_USER_ERROR:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          errors: action.payload,
        }
      };

    // Update user
    case USER_UPDATE:
      return {
        ...state,
        update: {
          isLoading: true,
          errors: null,
        },
      };

    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
        },
      };

    case USER_UPDATE_ERROR:
      return {
        ...state,
        update: {
          isLoading: false,
          errors: action.payload,
        },
      };

    // toggle enable user
    case TOGGLE_ENABLE_USER:
      return {
        ...state,
        toggleEnable: {
          isLoading: true,
          errors: null,
        },
      };

    case TOGGLE_ENABLE_USER_SUCCESS:
      return {
        ...state,
        toggleEnable: {
          ...state.toggleEnable,
          isLoading: false,
        },
      };

    case TOGGLE_ENABLE_USER_ERROR:
      return {
        ...state,
        toggleEnable: {
          isLoading: false,
          errors: action.payload,
        },
      };

    // Me
    case USER_GET_ME:
      return {
        ...state,
        me: {
          ...state.me,
          isLoading: true,
          errors: null,
        },
      };

    case USER_GET_ME_SUCCESS:
      return {
        ...state,
        me: {
          ...state.me,
          data: action.payload,
          isLoading: false,
        },
      };

    case USER_GET_ME_ERROR:
      return {
        ...state,
        me: {
          ...state.me,
          errors: action.payload,
        },
      };

    default:
      return state;
  };
};