import { BarsOutlined, CaretDownOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Dropdown, Menu, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { IUser } from '../../../../utils/types/user';

interface IConnectedUserMenuProps {
  user: IUser;
  loading: boolean;
}

const profileMenu = (user: IUser) => (
  <Menu>
    <Menu.Item key="0">
      <Link to={`/users/${user.id}`}>
        <Row>
          <Col span={4}>
            <UserOutlined />
          </Col>
          <Col span={20}>Mon Profil</Col>
        </Row>
      </Link>
    </Menu.Item>

    <Menu.Item key="1">
      <Link to="/leaves">
        <Row>
          <Col span={4}>
            <BarsOutlined />
          </Col>
          <Col span={20}>Mes demandes de congés</Col>
        </Row>
      </Link>
    </Menu.Item>
  </Menu>
);

export const ConnectedUserMenu: React.FC<IConnectedUserMenuProps> = ({
  user,
  loading,
}) => {
  return (
    <Dropdown overlay={profileMenu(user)} trigger={loading ? ['contextMenu'] : ['click']}>
      <Row className="sider-user-coords" gutter={8} justify="center" align="middle">
        <Col span={7}>
          <Avatar
            shape="square"
            size={40}
            src={user.avatar && `${process.env.REACT_APP_BASE_URL}${user.avatar.url}`}
            icon={!user.avatar && <UserOutlined />}
          />
        </Col>
        <Col span={15}>
          <div className="welcome-text">Bienvenue,</div>
          <div className="username">
            <strong>
              {user.firstName} {user.lastName}
            </strong>
          </div>
        </Col>
        <Col span={2}>
          <CaretDownOutlined />
        </Col>
      </Row>
    </Dropdown>
  )
}
