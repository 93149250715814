import { Layout } from 'antd';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { EmptyContent } from '../../../components/EmptyContent';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { cancelLeave, leaveGetById, leaveGetByIdClear, leaveUpdate } from '../../../store/leave/actions';
import { GlobalState } from '../../../store/root-reducer';
import { ICreateLeave } from '../../../utils/types/leave';
import { IPageProps } from '../../../utils/types/types';
import { UpdateExceptionalLeaveForm } from './components/UpdateExceptionalLeaveForm';
import { UpdateLeaveForm } from './components/UpdateLeaveForm';

interface IUpdateLeavePageProps extends IPageProps {

}

const UpdateLeaveInner: React.FC<IUpdateLeavePageProps & ConnectedProps<typeof connector>> = ({
  match,
  route,
  leave,
  canceLeave,
  leaveGetById: leaveGetByIdAction,
  leaveUpdate: leaveUpdateAction,
  cancelLeave: cancelLeaveACtion,
  leaveGetByIdClear: leaveGetByIdClearAction,
}) => {

  useEffect(() => {
    if (match.params.id) leaveGetByIdAction(match.params.id);
  }, [leaveGetByIdAction, match.params.id]);

  useEffect(() => {
    return () => {
      leaveGetByIdClearAction();
    };
  }, []);

  const handleUpdatedLeave = (updateLeave: ICreateLeave) => leave.data && leaveUpdateAction({ ...updateLeave, id: leave.data.id });

  const handleCancelLeave = () => leave.data && leave.data.id && cancelLeaveACtion(leave.data.id);

  if (!leave.data) return <EmptyContent description="Aucune congé disponible" />

  return (
    <PageSkeleton mainLayout={true} pageTitle={`${route.title} ${leave.data.type.name}`} goBackTo="/leaves">
      <Layout className="content-layout">
        {leave.data.type.slug !== 'sortie-exceptionnelle'
          ?
          <UpdateLeaveForm
            leaveName={leave.data.type.name}
            leave={leave}
            canceLeave={canceLeave}
            onUpdateLeave={handleUpdatedLeave}
            onCancelLeave={handleCancelLeave}
          />
          :
          <UpdateExceptionalLeaveForm leave={leave} />}
      </Layout>
    </PageSkeleton>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  leave: state.leaves.leave,
  canceLeave: state.leaves.cancel,
});

const mapDispatchToProps = {
  leaveGetById,
  leaveUpdate,
  cancelLeave,
  leaveGetByIdClear,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const UpdateLeavePage = connector(UpdateLeaveInner);
