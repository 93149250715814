import { GoogleOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Space } from 'antd';
import React from 'react';
import GoogleLogin from 'react-google-login';
import { Logo } from '../../../../components/Logo';
import { clientId } from '../../../../utils/vars';

interface IGoogleLoginButtonProps {
  loginErrors: string | null;
  onLoginClick: () => void;
  responseGoogle: (data: any) => void;
}

export const GoogleLoginButton: React.FC<IGoogleLoginButtonProps> = ({ onLoginClick, responseGoogle, loginErrors }) => {
  return (
    <Card title={<Logo location='block' />} bordered={false} className='login-card'>

      <Space direction="vertical">
        <GoogleLogin
          clientId={clientId}
          render={renderProps => (

            <Button type='primary' icon={<GoogleOutlined />} onClick={
              () => {
                renderProps.onClick();
                onLoginClick();
              }
            } disabled={renderProps.disabled}>
              Connexion via Google
            </Button>

          )}
          buttonText="Login"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
        />
        {
          !!loginErrors && <Alert message={loginErrors} type="error" showIcon closable />
        }
      </Space>

    </Card>
  )
}
