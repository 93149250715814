import React from 'react';
import { useWindowResize } from '../../hooks';

export const DesktopMode: React.FC<{}> = ({ children }) => {
  const { isMobile } = useWindowResize();
  return <>{!isMobile && children}</>;
};

export const MobileMode: React.FC<{}> = ({ children }) => {
  const { isMobile } = useWindowResize();
  return <>{isMobile && children}</>;
};
