import { Spin } from "antd";
import React, { useContext, useEffect } from "react";
import { PageSkeleton } from "../../components/PageSkeleton";
import { AuthContext } from "../../context/AuthContext";
import { IAuthContext, IPageProps } from "../../utils/types/types";
import { GoogleLoginButton } from './components/GoogleLoginButton/index';
import "./login.less";

interface ILoginPageProps extends IPageProps { }

export const LoginPage: React.FC<ILoginPageProps> = ({ route, history }) => {

	const { login, loginError, state, makeLoading }: IAuthContext = useContext(AuthContext);

	useEffect(() => {
		if (state.isLoggedIn) history.push('/');
	}, [state, history]);

	const responseGoogle = (response: any) => {
		if (response.error) {
			loginError(null);
			return;
		}
		else if (!response.tokenObj.access_token) {
			loginError("Un problème avec le service de connexion Google, veuillez actualiser et réessayer!");
			return;
		}
		/*else if (response.profileObj.email.endsWith('symdrik.com'))*/ login(response.tokenObj.access_token, response.tokenObj.expires_at, response.profileObj.email);
		// else loginError(`Vous n'êtes pas autorisé à accéder avec le domaine ${response.profileObj.email.split('@')[1]}`);
	};

	return (
		<PageSkeleton mainLayout={false} pageTitle={route.title}>
			<div className='flex-wrapper'>
				<div className="login-wrapper">
					<Spin spinning={state.isLoading}>
						<GoogleLoginButton onLoginClick={makeLoading} responseGoogle={responseGoogle} loginErrors={state.errors} />
					</Spin>
				</div>
			</div>
		</PageSkeleton>
	);
};
