import { UserOutlined } from '@ant-design/icons';
import { Avatar, Drawer, List, Space, Typography } from 'antd';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import moment from 'moment';
import React from 'react';
import { EmptyContent } from '../../../../../components/EmptyContent';
import { ILeave } from '../../../../../utils/types/leave';
import './calendar-drawer.less';

const { Title, Text } = Typography;

interface ICalendarDrawerProps {
  selectedDate?: moment.Moment;
  mode: CalendarMode;
  leaves: ILeave[];
  isVisible: boolean;
  onDrawerClose: () => void;
};

export const CalendarDrawer: React.FC<ICalendarDrawerProps> = ({
  selectedDate,
  mode,
  leaves,
  isVisible,
  onDrawerClose,
}) => {

  const format = 'DD-MM-YYYY';

  const renderXDayLeaves = (selectedDate: moment.Moment, listLeaves: ILeave[]) => listLeaves.filter(({ startingDate, endingDate }) => moment(selectedDate).isBetween(moment(startingDate), moment(endingDate).add(1, 'day')));

  if (!selectedDate) return null;

  return (
    <Drawer
      title={<Title level={5}>Liste des congés</Title>}
      className="calendar-drawer"
      placement="right"
      onClose={onDrawerClose}
      visible={isVisible}
    >
      <Title level={4}>
        {mode === 'month' ? 'Jour' : 'Mois'}: {mode === 'month' ? moment(selectedDate).format(format) : moment(selectedDate).format('MMMM')}</Title>
      <List
        itemLayout="horizontal"
        locale={{
          emptyText: <EmptyContent description="Aucune demande de congé !" />
        }}
        dataSource={renderXDayLeaves(selectedDate, leaves)}
        renderItem={leave => (
          <List.Item>
            <List.Item.Meta
              avatar={(
                <Avatar
                  src={leave.user.avatar && leave.user.avatar.url}
                  icon={!leave.user.avatar && <UserOutlined />}
                />
              )}
              title={`${leave.user.firstName} ${leave.user.lastName}`}
              description={(
                <Space direction="vertical">
                  <Text>De: <Text strong>{moment(leave.startingDate).format(format)}</Text></Text>
                  <Text>À: <Text strong>{moment(leave.endingDate).format(format)}</Text></Text>
                </Space>
              )}
            />
          </List.Item>
        )}
      />
    </Drawer>
  );
};
