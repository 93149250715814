import { ICreateLeave, IGetMyLeavesWithFilters, ILeave, ILeaveDecision, ILeaveType } from "../../utils/types/leave";
import { IErrors } from "../../utils/types/types";
import { GET_MY_LEAVES, GET_MY_LEAVES_ERROR, GET_MY_LEAVES_SUCCESS, IGetMyLeavesAction, IGetMyLeavesErrorAction, IGetMyLeavesSuccessAction, ILeaveAddRequestAction, ILeaveAddRequestErrorAction, ILeaveAddRequestResetCreatedAction, ILeaveAddRequestSuccessAction, ILeaveCancelAction, ILeaveCancelErrorAction, ILeaveCancelSuccessAction, ILeaveDecisionUpdateAction, ILeaveDecisionUpdateErrorAction, ILeaveDecisionUpdateResetAction, ILeaveDecisionUpdateSuccessAction, ILeaveGetByIdAction, ILeaveGetByIdClearAction, ILeaveGetByIdErrorAction, ILeaveGetByIdSuccessAction, ILeaveGetListAction, ILeaveGetListErrorAction, ILeaveGetListSuccessAction, ILeavesTypesGetListAction, ILeavesTypesGetListErrorAction, ILeavesTypesGetListSuccessAction, ILeaveUpdateAction, ILeaveUpdateErrorAction, ILeaveUpdateSuccessAction, IListLeavesFilter, LEAVES_TYPES_GET_LIST, LEAVES_TYPES_GET_LIST_ERROR, LEAVES_TYPES_GET_LIST_SUCCESS, LEAVE_ADD_REQUEST, LEAVE_ADD_REQUEST_ERROR, LEAVE_ADD_REQUEST_RESET_CREATED, LEAVE_ADD_REQUEST_SUCCESS, LEAVE_CANCEL, LEAVE_CANCEL_ERROR, LEAVE_CANCEL_SUCCESS, LEAVE_DECISION_UPDATE, LEAVE_DECISION_UPDATE_ERROR, LEAVE_DECISION_UPDATE_RESET, LEAVE_DECISION_UPDATE_SUCCESS, LEAVE_GET_BY_ID, LEAVE_GET_BY_ID_CLEAR, LEAVE_GET_BY_ID_ERROR, LEAVE_GET_BY_ID_SUCCESS, LEAVE_GET_LIST, LEAVE_GET_LIST_ERROR, LEAVE_GET_LIST_SUCCESS, LEAVE_UPDATE, LEAVE_UPDATE_ERROR, LEAVE_UPDATE_SUCCESS } from "./types";

/**
 * Add leave request
 */
export const leaveAddRequest = (leave: ICreateLeave): ILeaveAddRequestAction => ({
  type: LEAVE_ADD_REQUEST,
  payload: leave,
});

export const leaveAddRequestSuccess = (): ILeaveAddRequestSuccessAction => ({
  type: LEAVE_ADD_REQUEST_SUCCESS,
});

export const leaveAddRequestError = (errors: IErrors): ILeaveAddRequestErrorAction => ({
  type: LEAVE_ADD_REQUEST_ERROR,
  payload: errors,
});

export const leaveAddRequestResetCreated = (): ILeaveAddRequestResetCreatedAction => ({
  type: LEAVE_ADD_REQUEST_RESET_CREATED,
});

/**
 * Get List leaves types
 */
export const leaveTypesGetList = (): ILeavesTypesGetListAction => ({
  type: LEAVES_TYPES_GET_LIST,
});

export const leaveTypesGetListSuccess = (leaveTypes: ILeaveType[]): ILeavesTypesGetListSuccessAction => ({
  type: LEAVES_TYPES_GET_LIST_SUCCESS,
  payload: leaveTypes,
});

export const leaveTypesGetListError = (errors: IErrors): ILeavesTypesGetListErrorAction => ({
  type: LEAVES_TYPES_GET_LIST_ERROR,
  payload: errors,
});

/**
 * Get List leaves
 */
export const leaveGetList = (filters?: IListLeavesFilter): ILeaveGetListAction => ({
  type: LEAVE_GET_LIST,
  payload: filters,
});

export const leaveGetListSuccess = (leaves: ILeave[]): ILeaveGetListSuccessAction => ({
  type: LEAVE_GET_LIST_SUCCESS,
  payload: leaves,
});

export const leaveGetListError = (errors: IErrors): ILeaveGetListErrorAction => ({
  type: LEAVE_GET_LIST_ERROR,
  payload: errors,
});

/**
 * Get Leave by ID
 */
export const leaveGetById = (id: string): ILeaveGetByIdAction => ({
  type: LEAVE_GET_BY_ID,
  payload: id,
});

export const leaveGetByIdSuccess = (leave: ILeave): ILeaveGetByIdSuccessAction => ({
  type: LEAVE_GET_BY_ID_SUCCESS,
  payload: leave,
});

export const leaveGetByIdError = (errors: IErrors): ILeaveGetByIdErrorAction => ({
  type: LEAVE_GET_BY_ID_ERROR,
  payload: errors,
});

export const leaveGetByIdClear = (): ILeaveGetByIdClearAction => ({
  type: LEAVE_GET_BY_ID_CLEAR,
});

/**
 * Update Leave
 */
export const leaveUpdate = (leave: ICreateLeave): ILeaveUpdateAction => ({
  type: LEAVE_UPDATE,
  payload: leave,
});

export const leaveUpdateSuccess = (): ILeaveUpdateSuccessAction => ({
  type: LEAVE_UPDATE_SUCCESS,
});

export const leaveUpdateError = (errors: IErrors): ILeaveUpdateErrorAction => ({
  type: LEAVE_UPDATE_ERROR,
  payload: errors,
});

/**
 * Cancel leave
 */
export const cancelLeave = (id: string): ILeaveCancelAction => ({
  type: LEAVE_CANCEL,
  payload: id,
});

export const cancelLeaveSuccess = (): ILeaveCancelSuccessAction => ({
  type: LEAVE_CANCEL_SUCCESS,
});

export const cancelLeaveError = (errors: IErrors): ILeaveCancelErrorAction => ({
  type: LEAVE_CANCEL_ERROR,
  payload: errors,
});

/**
 * Update Leave Decision
 */
export const leaveDecisionUpdate = (leaveDecision: ILeaveDecision): ILeaveDecisionUpdateAction => ({
  type: LEAVE_DECISION_UPDATE,
  payload: leaveDecision,
});

export const leaveDecisionUpdateSuccess = (): ILeaveDecisionUpdateSuccessAction => ({
  type: LEAVE_DECISION_UPDATE_SUCCESS,
});

export const leaveDecisionUpdateError = (errors: IErrors): ILeaveDecisionUpdateErrorAction => ({
  type: LEAVE_DECISION_UPDATE_ERROR,
  payload: errors,
});

export const leaveDecisionUpdateReset = (): ILeaveDecisionUpdateResetAction => ({
  type: LEAVE_DECISION_UPDATE_RESET,
});

/**
 * Get My Leaves
 */
export const getMyLeaves = (terms: IGetMyLeavesWithFilters): IGetMyLeavesAction => ({
  type: GET_MY_LEAVES,
  payload: terms,
});

export const getMyLeavesSuccess = (leaves: ILeave[]): IGetMyLeavesSuccessAction => ({
  type: GET_MY_LEAVES_SUCCESS,
  payload: leaves,
});

export const getMyLeavesError = (errors: IErrors): IGetMyLeavesErrorAction => ({
  type: GET_MY_LEAVES_ERROR,
  payload: errors,
});
