import { Avatar, Col, Popover, Row, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';

const { Text, Title } = Typography;

interface ILeavesBalanceProps {

};

export const LeavesBalance: React.FC<ILeavesBalanceProps> = () => {

  const [isPopoverVisible, setPopoverVisibility] = useState(false);

  return (
    <Tooltip title="Solde de congé annuel">
      <Popover
        title={<Title level={4}>Vots soldes de congés</Title>}
        content={(
          <>
            <Row gutter={[0, 16]} justify="space-between">
              <Col>
                <Text>Congé légal annuel</Text>
              </Col>
              <Col>
                <Text type="success" strong>10</Text>
              </Col>
            </Row>

            <Row gutter={[0, 16]} justify="space-between">
              <Col>
                <Text>Sortie exceptionnelle</Text>
              </Col>
              <Col>
                <Text type="success" strong>1</Text>
              </Col>
            </Row>

            <Row gutter={[0, 16]} justify="space-between">
              <Col>
                <Text>Congé sans solde</Text>
              </Col>
              <Col>
                <Text type="success" strong>5</Text>
              </Col>
            </Row>

            <Row gutter={[0, 16]} justify="space-between">
              <Col>
                <Text>Congé de maladie</Text>
              </Col>
              <Col>
                <Text type="success" strong>5</Text>
              </Col>
            </Row>

            <Row gutter={[0, 16]} justify="space-between">
              <Col>
                <Text>Congés spéciaux</Text>
              </Col>
              <Col>
                <Text type="success" strong>1</Text>
              </Col>
            </Row>

            <Row gutter={[0, 16]} justify="space-between">
              <Col>
                <Text>Congé de paternité</Text>
              </Col>
              <Col>
                <Text type="success" strong>2</Text>
              </Col>
            </Row>

            <Row gutter={[0, 16]} justify="space-between">
              <Col>
                <Text>Congé de maternité</Text>
              </Col>
              <Col>
                <Text type="success" strong>2</Text>
              </Col>
            </Row>
          </>
        )}
        placement="bottomRight"
        trigger="click"
        visible={isPopoverVisible}
        onVisibleChange={setPopoverVisibility}
        overlayClassName="header-popover"
      >
        <Avatar className="info">10</Avatar>
      </Popover>
    </Tooltip>
  );
};
