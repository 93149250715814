import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { ICreateDepartment, IDepartment } from '../../../../../utils/types/department';
import { IUser } from '../../../../../utils/types/user';

interface IModalUpdateDepartmentProps {
  department: IDepartment;
  users: IUser[];
  isModalVisible: boolean;
  onCloseModal: (state: boolean) => void;
  onUpdateFormSubmited: (department: ICreateDepartment) => void;
}

export const ModalUpdateDepartment: React.FC<IModalUpdateDepartmentProps> = ({
  department,
  users,
  isModalVisible,
  onCloseModal,
  onUpdateFormSubmited
}) => {

  const { Item } = Form;
  const { TextArea } = Input;
  const { Option } = Select;

  const [form] = Form.useForm();

  const [currentDepartment, setCurrentDepartment] = useState<IDepartment | undefined>(undefined);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    setCurrentDepartment(department);
  }, [department]);

  const handleClose = () => onCloseModal(false);

  const handleUpdateFormSubmited = (values: any) => {
    if (values && department) {
      onUpdateFormSubmited({ ...values, id: department.id });
    }
  };

  return (
    <>
      {
        currentDepartment && (
          <Modal
            title={`Modification du ${department.title}`}
            visible={isModalVisible}
            closable={false}
            footer={[
              (<Button key="no" type="primary" onClick={handleClose}>Annuler</Button>),
              (<Button key="yes" type="primary" onClick={form.submit}>Modifier</Button>)
            ]}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={handleUpdateFormSubmited}
              initialValues={currentDepartment}
            >

              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Item name="title" label="Titre:">
                    <Input type="text" placeholder="Titre" />
                  </Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Item name="description" label="Description:">
                    <TextArea placeholder="Description" />
                  </Item>
                </Col>
              </Row>


              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Item name="responsible" label="Choisir un chef de pôle:">
                    <Select placeholder="Choisir un chef de pôle pour ce département">
                      {
                        users.map(user => <Option key={user.id} value={`/users/${user.id}`}>{user.firstName} {user.lastName}</Option>)
                      }
                    </Select>
                  </Item>
                </Col>
              </Row>

            </Form>
          </Modal>
        )
      }
    </>
  )
};
