import { Empty } from 'antd';
import React from 'react';

const { PRESENTED_IMAGE_SIMPLE } = Empty;

interface IEmptyContentProps {
  description: string | React.ReactNode;
}

export const EmptyContent: React.FC<IEmptyContentProps> = (props) => {
  return (
    <Empty {...props} image={PRESENTED_IMAGE_SIMPLE} />
  );
};
